
var TAG = "Constraints";
var Constraints;

var ConstraintFactory = (function(){
    Constraints = function Constraints () {
        // constructor(){
            this.lastN = -1;
            this.defaultConstraints = {}
            this.onStageEndpoints = []
            this.selectedEndpoints = []
            this.constraints = {}
            this.object = {}
            
            this.init = function init(){
                this.object.lastN = this.lastN
                this.object.selectedEndpoints = this.selectedEndpoints
                this.object.onStageEndpoints = this.onStageEndpoints
                this.object.defaultConstraints = this.defaultConstraints
                this.object.constraints = this.constraints
            }


            this.getConstrains = function getConstrains() {
                return this.object;
            }

            this.setLastN = function setLastN(lastN) {
                this.lastN = lastN;
                this.init();
            }

            this.setSelectedEndpoints = function setSelectedEndpoints(id) {
                this.selectedEndpoints = [id]
                this.init();
            }

            this.setStageEndPoints = function setStageEndPoints(id) {
                this.onStageEndpoints = [id]
                this.init();
            }

            this.setDefaultConstraints = function setDefaultConstraints(maxHeight){
                this.defaultConstraints = { 'maxHeight': maxHeight }
                this.init();
            }

            this.setConstraints = function setConstraints(id){
                this.constraints[id] = {'maxHeight':720}
            }

            this.clear = function clear(){
                this.lastN = -1;
                this.defaultConstraints = {}
                this.onStageEndpoints = []
                this.selectedEndpoints = []
                this.constraints = {}
                this.object = {}
                this.init();
            }
        // } 
    }
    var instance;
    return {
        getInstance: function(){
            if (instance == null) {
                instance = new Constraints();
                instance.init();
                instance.constructor = null;
            }
            return instance;
        }
    };
})();
export default ConstraintFactory