/* global  JitsiMeetJS */
/* eslint-disable */
import React from "react";
import {
  TRACK_DESKTOP,
  initOptions,
  confOptions,
  confOptionsLegacy,
  SCREEN_SHARING_STARTED,
  LocalizedString,
} from "../../Utils/Constants";
import Logger from "../../lib/Logger";
const TAG = "ScreenShare";
const USER_OBJECT = "user_object";

var connection = null,
  roomName = null,
  room = null,
  isJoined = false,
  userID;
export default class ScreenShare extends React.PureComponent {
  start = () => {
    Logger.log(TAG, "[start]", this.props.sessionID);
    this.createLocalTracks();
  };

  localUser = { ...this.props.user, videoTrack: null, audioTrack: null };

  init = (sessionID) => {
    Logger.log(TAG, "[init]", initOptions);
    roomName = sessionID;
    JitsiMeetJS.init(initOptions);
    ("");
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    this.makeConnection();
  };

  makeConnection() {
    Logger.log(TAG, "[makeConnection]");

    if (this.props.isLegacyServer) {
      var options = {
        hosts: {
          domain: this.props.domain,
          muc: this.props.MUC,
          focus: this.props.focus,
        },
        bosh: this.props.boshLegacy,
        clientNode: "http://jitsi.org/jitsimeet",
      };
    } else {
      var options = {
        hosts: {
          domain: this.props.domain,
          muc: this.props.MUC,
          focus: this.props.focus,
        },
        serviceUrl: this.props.webSocket,
        bosh: this.props.getBosh,
        clientNode: "http://jitsi.org/jitsimeet",
      };
    }
    connection = new JitsiMeetJS.JitsiConnection(null, null, options);
    connection.connect();
    this.addEventListener();
  }

  addEventListener = () => {
    Logger.log(TAG, "[addEventListener]", 4);
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess
    );
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      () => {}
    );
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      () => {}
    );
  };

  createLocalTracks = () => {
    JitsiMeetJS.createLocalTracks({
      devices: [TRACK_DESKTOP],
      desktopSharingSources: ["screen", "window"],
      facingMode: "user",
      resolution: 1080,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: { ideal: 720, max: 720, min: 405 },
          width: { ideal: 1280, max: 1280, min: 720 },
        },
      },
    })
      .then((t) => {
        if (t.length > 1) {
          for (var i = 0; i < t.length; i++) {
            if (t[i].track.kind == "video") {
              this.localUser.videoTrack = t[i];
              this.localUser.isVideoMuted = false;
            } else if (t[i].track.kind == "audio") {
              this.localUser.isAudioMuted = false;
              this.localUser.audioTrack = t[i];
            }
          }
        } else {
          this.localUser.videoTrack = t[0];
        }

        // To handle Chrome/extension's stop button click
        this.localUser.videoTrack.track.onended = () => {
          this.stop();
        };
        this.props.onTrackCreated(this.localUser.videoTrack);

        this.init(this.props.sessionID);
      })
      .catch((error) => {
        this.props.onError(error);
        this.stop(false);
        Logger.log("ScreenShare", error);
      });
  };

  onConnectionSuccess = () => {
    try {
      if (this.props.isLegacyServer) {
        room = connection.initJitsiConference(roomName, confOptionsLegacy);
      } else {
        room = connection.initJitsiConference(roomName, confOptions);
      }
      isJoined = true;
      Logger.log(
        TAG,
        "[onConnectionSuccess]",
        "screenshare flow",
        6,
        room.myUserId(),
        this.localUser.name
      );

      window.room = room;
      userID = room.myUserId() || "";
      room.setDisplayName(this.localUser.name + LocalizedString.SCREEN_SHARE_POSTFIX);
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onConferenceJoined
      );
      room.join();
    } catch (error) {
      this.props.onError();
      Logger.log(TAG, "[onConnectionSuccess]", "error", error);
    }
  };

  onConferenceJoined = async () => {
    room.addTrack(this.localUser.videoTrack);
    if (this.localUser.audioTrack) {
      room.addTrack(this.localUser.audioTrack);
    }
    room.sendCommand(SCREEN_SHARING_STARTED, {
      value: JSON.stringify({userId: this.localUser.id, screenShareId: userID}),
    });
    this.props.onSuccess(room.myUserId());
  };

  stop = (sendEvent = true) => {
    try {
      if (sendEvent) {
        this.props.onStopped();
      }
      if (this.localUser.videoTrack) {
        this.localUser.videoTrack
          .dispose()
          .then(() => {})
          .catch(() => {});
      }
      if (this.localUser.audioTrack) {
        this.localUser.audioTrack
          .dispose()
          .then(() => {})
          .catch(() => {});
      }
      if (room && room)
        room
          .leave()
          .then(() => connection.disconnect())
          .catch((e) => {
            console.log(e);
          });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return null;
  }
}
