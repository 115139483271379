/* eslint-disable */
import React from "react";

export const PublicIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      width="24"
      height="24"
      fill="white"
      className="CEJND cIGbvc NMm5M"
    >
      <path d="M15 8c0-1.42-.5-2.73-1.33-3.76.42-.14.86-.24 1.33-.24 2.21 0 4 1.79 4 4s-1.79 4-4 4c-.43 0-.84-.09-1.23-.21-.03-.01-.06-.02-.1-.03A5.98 5.98 0 0 0 15 8zm1.66 5.13C18.03 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.58-3.47-6.34-3.87zM9 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 9c-2.7 0-5.8 1.29-6 2.01V18h12v-1c-.2-.71-3.3-2-6-2M9 4c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 9c2.67 0 8 1.34 8 4v3H1v-3c0-2.66 5.33-4 8-4z"></path>
    </svg>
  );
};

export const NetworkIcon = ({ stats, iconColor }) => {
  // if (!stats) {
  //   return false;
  // }
  const { connectionQuality, bandwidth } = stats;
  let color = iconColor || "#00000000";
  let title = "Poor";
  // if (connectionQuality >= 66) {
  //   color = "#3490DC";
  //   title = "Good";
  // } else if (connectionQuality >= 33) {
  //   color = "#E3342F";
  //   title = "Poor";
  // } else if (connectionQuality) {
  //   color = "#E3342F";
  //   title = "best";
  // }

  let tooltip = `
    Connectivity: ${title} 
  `;

  // if (bitrate) {
  //   tooltip += `Estimated Bandwidth :  &darr; ${bandwidth.download} Kbps  &uarr; ${bandwidth.upload} Kbps`;
  // }

  if (bandwidth) {
    tooltip += `Estimated Bandwidth :  &darr; ${
      bandwidth.download ? bandwidth.download : "-"
    } Kbps  &uarr; ${bandwidth.upload} Kbps`;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M2 22h20V2z" />
    </svg>
  );

  return (
    <span data-tip={tooltip}>
      <svg
        height="20"
        viewBox="0 0 512 512"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
      >
        <path d="m60 512c33.089844 0 61-26.910156 61-60s-27.910156-61-61-61-60 27.910156-60 61 26.910156 60 60 60zm0 0" />
        <path d="m15 300h30c91.125 0 167 75.871094 167 167v30c0 8.285156 6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15v-30c0-124.265625-102.734375-227-227-227h-30c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15zm0 0" />
        <path d="m15 180h30c157.402344 0 287 129.597656 287 287v30c0 8.285156 6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15v-30c0-190.539062-156.460938-347-347-347h-30c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15zm0 0" />
        <path d="m45 0h-30c-8.285156 0-15 6.714844-15 15v30c0 8.285156 6.714844 15 15 15h30c223.675781 0 407 183.324219 407 407v30c0 8.285156 6.714844 15 15 15h30c8.285156 0 15-6.714844 15-15v-30c0-256.8125-210.1875-467-467-467zm0 0" />
      </svg>
    </span>
  );
};
