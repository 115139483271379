/* global $, JitsiMeetJS */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import RTCManager from "../../lib/RTCManager";
import { useIsMouseMoving } from "../../lib/utils";
import "./ButtonBarComponent.css";
import "./CallingComponent.css";
import DeviceChangeIcon from "../../assets/device_change.png";
let timeout;
const BottomButtons = ({
  stopHover,
  shouldBeInCenter,
  localUser,
  isChatEnabled,
  onScreenShareClicked,
  onEndCallButtonCLicked,
  toggleAudioButton,
  toggleVideoButton,
  toggleChatButton,
  isScreenShareOn,
  isBroadcast,
  isBroadcaster,
  showEndCallButton,
  showAudioMuteButton,
  showVideoPauseButton,
  showScreenShareButton,
  showChangeModeButton,
  audioDevices,
  changeAudioSourceClicked,
  changeVideoSourceClicked,
  videoDevices,
  audioOutPutDevices,
  changeAudioOutputDeviceClicked,
  activeInputDevices,
  activeOutputDevices,
  orientation,
  room,
  localizedObject,
  isAudioOnly,
  isJoined,
  mode,
  changeMode,
  noOfUsers,
  startRecording,
  stopRecording,
  isRecording,
  isStreaming,
  startStreaming,
  recorder,
}) => {
  const value = useIsMouseMoving();
  const isMob = RTCManager.isMob();
  const [showButtons, setShowButtons] = useState(true);
  const [showAudioModel, setShowAudioModel] = useState(false);
  const [showAudioOutputModel, setShowAudioOutputModel] = useState(false);
  const [showVideoModel, setShowVideoModel] = useState(false);
  const [talkingWhileMuted, setTalkingWhileMuted] = useState(false);
  const [noisyMic, setNoisyMic] = useState(false);
  const [showModeModel, setShowModeModel] = useState(false);
  useEffect(() => {
    const eventHandler = () => {
      setTalkingWhileMuted(true);
      setShowButtons(true);
      setTimeout(() => {
        setShowButtons(false);
        setTalkingWhileMuted(false);
      }, 5000);
    };

    if (room) {
      room.on(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, eventHandler);
    }

    return () => {
      if (room) {
        room.off(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, eventHandler);
      }
    };
  }, [localUser]);

  useEffect(() => {
    const eventHandler = () => {
      setNoisyMic(true);
      setShowButtons(true);
      setTimeout(() => {
        setShowButtons(false);
        setNoisyMic(false);
      }, 5000);
    };

    if (room) {
      room.on(JitsiMeetJS.events.conference.NOISY_MIC, eventHandler);
    }

    return () => {
      if (room) {
        room.off(JitsiMeetJS.events.conference.NOISY_MIC, eventHandler);
      }
    };
  }, [localUser]);

  // useEffect(() => {
  //   setShowButtons(true);
  //   if (timeout) {
  //     clearTimeout(timeout);
  //   }

  //   timeout = setTimeout(() => {
  //     setShowButtons(false);
  //   }, 4000);
  // }, [value]);

  if (!isJoined) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => stopHover()}
      className={`button-bar stack-top pointer-events-none 
       ${
         (isMob && orientation == "Portrait") || shouldBeInCenter
           ? "padding-right-0"
           : "padding-right-12"
       }
      ${
        showButtons ||
        (isMob && orientation == "Portrait") ||
        showVideoModel ||
        showAudioModel ||
        showModeModel
          ? "animated fade-in-up"
          : "animated fade-in-down"
      }
     
        `}
    >
      <div className="button-container ">
        <div className="button-container  button-background2 custom-button-bar">
          <div className="flex pointer-events-auto">
            {showEndCallButton ? (
              <button
                type="button"
                data-tip="End Call"
                data-for="endCall"
                title={localizedObject["END_CALL_TEXT"]}
                className={`btn-circle custom-button ${
                  isMob ? "mob-btn red" : "red"
                }`}
                onClick={onEndCallButtonCLicked}
              >
                <span
                  style={isMob ? { height: "9vw", width: "9vw" } : {}}
                  className={"end-call-icon"}
                ></span>{" "}
              </button>
            ) : null}
            {showVideoPauseButton && !isAudioOnly ? (
              <div className="relative popup">
                <button
                  type="button"
                  data-for="videoState"
                  data-tip=""
                  className={`btn-circle custom-button popup ${
                    RTCManager.isMob() ? "mob-btn" : ""
                  }`}
                  onClick={toggleVideoButton}
                >
                  {!localUser.isVideoMuted ? (
                    <span
                      style={isMob ? { height: "9vw", width: "9vw" } : {}}
                      className="video-un-mute"
                    ></span>
                  ) : (
                    <span
                      style={isMob ? { height: "9vw", width: "9vw" } : {}}
                      className="video-mute"
                    ></span>
                  )}
                </button>

                {videoDevices && videoDevices.length > 1 && (
                  <div
                    data-for="videoSource"
                    onClick={() => {
                      setShowVideoModel(!showVideoModel);
                      setShowAudioModel(false);
                      setShowModeModel(false);
                    }}
                    style={isMob ? { height: "4vw", width: "4vw" } : {}}
                    data-tip="Change Video Source"
                    className="change-source-button"
                  >
                    <img src={DeviceChangeIcon} />
                  </div>
                )}
                {showVideoModel ? (
                  <div
                    style={{ width: RTCManager.isMob() ? "50vw" : "250px" }}
                    className="popup-text"
                    id="myPopup"
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#707070",
                        textAlign: "left",
                        padding: 8,
                      }}
                    >
                      {(localizedObject &&
                        localizedObject.SELECT_VIDEO_SOURCE) ||
                        "Select Video source"}
                    </p>
                    <div className="video-source-list">
                      {videoDevices &&
                        videoDevices.length &&
                        videoDevices.map((item) => {
                          return (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div>
                                <input
                                  type="radio"
                                  id={item.deviceId}
                                  name="videoSource"
                                  checked={
                                    localUser.videoTrack &&
                                    item.deviceId ==
                                      localUser.videoTrack.deviceId
                                      ? "checked"
                                      : ""
                                  }
                                  onClick={() => {
                                    changeVideoSourceClicked(item.deviceId);
                                    setShowVideoModel(false);
                                  }}
                                />
                              </div>
                              <div>
                                <label
                                  style={{
                                    color:
                                      localUser.videoTrack &&
                                      item.deviceId ==
                                        localUser.videoTrack.deviceId
                                        ? "#458B00"
                                        : "white",
                                    fontWeight:
                                      localUser.videoTrack &&
                                      item.deviceId ==
                                        localUser.videoTrack.deviceId
                                        ? "bold"
                                        : "white",
                                    marginLeft: 5,
                                  }}
                                  for={item.deviceId}
                                >
                                  {item.label}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {/* <div className="video-source-list">
                        {videoDevices &&
                          videoDevices.length &&
                          videoDevices.map((item) => {
                            return (
                              <span
                                onClick={() => {
                                  changeVideoSourceClicked(item.deviceId);
                                  setShowVideoModel(false);
                                }}
                                style={{
                                  margin: 10,
                                  color:
                                    localUser.videoTrack &&
                                    item.deviceId ==
                                      localUser.videoTrack.deviceId
                                      ? "#000"
                                      : "white",
                                }}
                              >
                                {item.label}
                              </span>
                            );
                          })}
                      </div> */}
                  </div>
                ) : null}
              </div>
            ) : null}
            {showAudioMuteButton ? (
              <div className="relative popup">
                <button
                  type="button"
                  data-for="audioState"
                  style={{ position: "relative" }}
                  className={`${
                    talkingWhileMuted || noisyMic ? "tool" : ""
                  } btn-circle custom-button ${
                    RTCManager.isMob() ? "mob-btn" : ""
                  } text-2xl`}
                  // data-tip={
                  //   talkingWhileMuted
                  //     ? "Are you speaking? Your mic is currently muted"
                  //     : "You seem to have a lot of background noise. It might distract other users on the call"
                  // }
                  data-tip=""
                  id="videoState"
                  onClick={toggleAudioButton}
                >
                  {!localUser.isAudioMuted ? (
                    <span
                      style={isMob ? { height: "9vw", width: "9vw" } : {}}
                      className="audio-un-mute"
                    ></span>
                  ) : (
                    <span
                      style={isMob ? { height: "9vw", width: "9vw" } : {}}
                      className="audio-mute"
                    ></span>
                  )}{" "}
                </button>
                {((audioDevices && audioDevices.length > 1) ||
                  (audioOutPutDevices && audioOutPutDevices.length > 1)) && (
                  <div
                    data-for="audioSource"
                    onClick={() => {
                      setShowAudioModel(!showAudioModel);
                      setShowVideoModel(false);
                      setShowModeModel(false);
                    }}
                    data-tip="Change Audio Source"
                    style={isMob ? { height: "4vw", width: "4vw" } : {}}
                    className="change-source-button"
                  >
                    <img src={DeviceChangeIcon} />
                  </div>
                )}
                {showAudioModel ? (
                  <div
                    style={{ width: RTCManager.isMob() ? "50vw" : "250px" }}
                    className="popup-text"
                    id="myPopup"
                  >
                    {audioDevices && audioDevices.length > 1 ? (
                      <>
                        <p
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#707070",
                            textAlign: "left",
                            padding: 8,
                          }}
                        >
                          {(localizedObject &&
                            localizedObject.SELECT_INPUT_AUDIO_SOURCE) ||
                            "Select input audio source"}
                        </p>
                        <div className="video-source-list">
                          {audioDevices.map((item) => {
                            return (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div>
                                  <input
                                    type="radio"
                                    checked={
                                      activeInputDevices == item.deviceId
                                        ? "checked"
                                        : ""
                                    }
                                    id={item.deviceId}
                                    name="audioInput"
                                    onClick={() => {
                                      changeAudioSourceClicked(item.deviceId);
                                      setShowAudioModel(false);
                                    }}
                                    style={{
                                      margin: 10,
                                      color:
                                        item.deviceId == activeInputDevices
                                          ? "#000"
                                          : "white",
                                    }}
                                  />
                                </div>
                                <div>
                                  <label
                                    style={{
                                      color:
                                        item.deviceId == activeInputDevices
                                          ? "#458B00"
                                          : "white",
                                      fontWeight:
                                        item.deviceId == activeInputDevices
                                          ? "bold"
                                          : "white",
                                      marginLeft: 5,
                                    }}
                                    for={item.deviceId}
                                  >
                                    {item.label}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    {audioOutPutDevices && audioOutPutDevices.length > 1 ? (
                      <>
                        <p
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#707070",
                            textAlign: "left",
                            padding: 8,
                          }}
                        >
                          {(localizedObject &&
                            localizedObject.SELECT_OUTPUT_AUDIO_SOURCE) ||
                            "Select output audio source"}
                        </p>
                        <div className="video-source-list">
                          {audioOutPutDevices &&
                            audioOutPutDevices.map((item) => {
                              return (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  onClick={() => {
                                    changeAudioOutputDeviceClicked(
                                      item.deviceId
                                    );
                                  }}
                                >
                                  <div>
                                    <input
                                      type="radio"
                                      id={item.deviceId}
                                      name="audioOutDevices"
                                      checked={
                                        activeOutputDevices == item.deviceId
                                          ? "checked"
                                          : ""
                                      }
                                      style={{
                                        margin: 10,
                                        color:
                                          activeOutputDevices == item.deviceId
                                            ? "#000"
                                            : "white",
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <label
                                      style={{
                                        color:
                                          item.deviceId == activeOutputDevices
                                            ? "#458B00"
                                            : "white",
                                        fontWeight:
                                          item.deviceId == activeOutputDevices
                                            ? "bold"
                                            : "white",
                                        marginLeft: 5,
                                      }}
                                      for={item.deviceId}
                                    >
                                      {item.label}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            {showScreenShareButton ? (
              <div className="relative">
                {RTCManager.isMob() ? null : (
                  <button
                    type="button"
                    data-for="screenSharingState"
                    className={"btn-circle custom-button"}
                    onClick={onScreenShareClicked}
                    data-tip=""
                  >
                    {!isScreenShareOn ? (
                      <span className="screen-share-on"></span>
                    ) : (
                      <span className="screen-share-off"></span>
                    )}
                  </button>
                )}
              </div>
            ) : null}
            {showChangeModeButton ? (
              <div className="relative popup">
                {RTCManager.isMob() ? null : (
                  <button
                    type="button"
                    data-for="screenSharingState"
                    className={"btn-circle custom-button"}
                    onClick={() => {
                      setShowAudioModel(false);
                      setShowVideoModel(false);
                      setShowModeModel(!showModeModel);
                    }}
                    data-tip=""
                  >
                    <span className="display-mode-change"></span>
                  </button>
                )}
                {showModeModel ? (
                  <div
                    style={{ width: RTCManager.isMob() ? "50vw" : "250px" }}
                    className="popup-text "
                    id="myPopup"
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#707070",
                        textAlign: "left",
                        padding: 8,
                      }}
                    >
                      {(localizedObject && localizedObject.SELECT_MODE) ||
                        "Select Mode"}
                    </p>
                    <div className="video-source-list">
                      {/* //default */}
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <input
                            type="radio"
                            checked={
                              mode == "DEFAULT" || mode == "TILE" || !mode
                                ? "checked"
                                : ""
                            }
                            id={"DEFAULT"}
                            name="audioInput"
                            onClick={() => {
                              changeMode("DEFAULT");
                              setShowModeModel(false);
                            }}
                            style={{
                              margin: 10,
                              color:
                                mode == "DEFAULT" || mode == "TILE" || !mode
                                  ? "#000"
                                  : "white",
                            }}
                          />
                        </div>
                        <div style={{ paddingTop: 5 }}>
                          <label
                            style={{
                              color:
                                mode == "DEFAULT" || mode == "TILE" || !mode
                                  ? "#458B00"
                                  : "white",
                              fontWeight:
                                mode == "DEFAULT" || mode == "TILE" || !mode
                                  ? "bold"
                                  : "white",
                              marginLeft: 5,
                            }}
                            for={"DEFAULT"}
                          >
                            {"Tile"}
                          </label>
                        </div>
                      </div>
                      {/* spotlight  */}
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <input
                            type="radio"
                            checked={mode == "SPOTLIGHT" ? "checked" : ""}
                            id={"SPOTLIGHT"}
                            name="audioInput"
                            onClick={() => {
                              changeMode("SPOTLIGHT");
                              setShowModeModel(false);
                            }}
                            style={{
                              margin: 10,
                              color: mode == "SPOTLIGHT" ? "#000" : "white",
                            }}
                          />
                        </div>
                        <div style={{ paddingTop: 5 }}>
                          <label
                            style={{
                              color: mode == "SPOTLIGHT" ? "#458B00" : "white",
                              fontWeight:
                                mode == "SPOTLIGHT" ? "bold" : "white",
                              marginLeft: 5,
                            }}
                            for={"SPOTLIGHT"}
                          >
                            {"Spotlight"}
                          </label>
                        </div>
                      </div>
                      {/* GRID mode  */}
                      {noOfUsers < 17 ? (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              checked={mode == "GRID" ? "checked" : ""}
                              id={"GRID"}
                              name="audioInput"
                              onClick={() => {
                                changeMode("GRID");
                                setShowModeModel(false);
                              }}
                              style={{
                                margin: 10,
                                color: mode == "GRID" ? "#000" : "white",
                              }}
                            />
                          </div>
                          <div style={{ paddingTop: 5 }}>
                            <label
                              style={{
                                color: mode == "GRID" ? "#458B00" : "white",
                                fontWeight: mode == "GRID" ? "bold" : "white",

                                marginLeft: 5,
                              }}
                              for={"GRID"}
                            >
                              {"Grid"}
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* <button
                  style={{ color: "white" }}
                  onClick={() => {
                    startRecording();
                  }}
                >
                  start recording
                </button>
                <button
                  style={{ color: "white" }}
                  onClick={() => {
                    stopRecording();
                  }}
                >
                  stop recording
                </button>*/}
              </div>
            ) : null}
            {/* <div className={`dropdown ${isRecording ? "red" : ""}`}>
              {(isRecording && recorder) || !isRecording ? (
                <button
                  type="button"
                  className={`btn-circle ${isRecording ? "red" : ""}`}
                  onClick={isRecording ? stopRecording : startRecording}
                >
                  <span className={"more-option"}></span>
                </button>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomButtons;
