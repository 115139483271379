export class Helper {
  // static getSVGAvatar = (gen, d, shape) => {
  //   let generator = gen.toUpperCase();
  //   let data = d.toUpperCase();

  //   const svg1 = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  //   svg1.setAttribute("width", "100");
  //   svg1.setAttribute("height", "100");
  //   const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
  //   rect.setAttribute("x", "0");
  //   rect.setAttribute("y", "0");
  //   rect.setAttribute("width", "100");
  //   rect.setAttribute("height", "100");
  //   if (shape === "circle") {
  //     rect.setAttribute("rx", "55");
  //   }
  //   rect.setAttribute("fill", Helper.stringToColour(generator));

  //   const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
  //   text.setAttribute("x", "50%");
  //   text.setAttribute("y", "54%");
  //   text.setAttribute("dominant-baseline", "middle");
  //   text.setAttribute("text-anchor", "middle");
  //   text.setAttribute("fill", "white");
  //   text.setAttribute("font-size", "50");
  //   text.setAttribute("font-family", "'Inter', sans-serif");
  //   text.setAttribute("font-weight", "600");
  //   text.textContent = data;
  //   svg1.appendChild(rect);
  //   svg1.appendChild(text);

  //   let svgString = new XMLSerializer().serializeToString(svg1);
  //   let decoded = unescape(encodeURIComponent(svgString));
  //   let base64 = btoa(decoded);

  //   let imgSource = `data:image/svg+xml;base64,${base64}`;
  //   return imgSource;
  // };

  static getSVGAvatar = (generator, data) => {
    const stringToColour = function (str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      let colour = "#";
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
      return colour;
    };

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    // Draw background
    context.fillStyle = stringToColour(generator);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 100px 'Inter', sans-serif";
    context.fillStyle = "white"; //foregroundColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(
      /^[a-z0-9]+$/i.test(data) ? data.toUpperCase() : "U",
      canvas.width / 2,
      canvas.height / 2
    );

    return canvas.toDataURL("image/png");
  };

  static stringToColour = function (str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xf0;
      colour += ("00" + value.toString(16)).substr(-2);
    }

    return colour + "";
  };
}

export const isInStandaloneMode = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  window.navigator.standalone ||
  document.referrer.includes("android-app://");

export const toPascalCase = (name) => {
  return name?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};
