import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import { Helper } from "../../../lib/helper";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "../CallingComponent.css";
import RTCManager from "../../../lib/RTCManager";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: "auto",
    // height: theme.spacing(4),
    maxHeight: "90%",
    height: "50%",
    aspectRatio: 1,

    // opacity:Math.random(0.6,1)
  },
  medium: {
    width: "auto",
    height: "50%",
    maxHeight: "90%",
    // height: theme.spacing(20),
    aspectRatio: 1,

    // opacity:Math.random(0.6,1)
  },
  large: {
    width: "auto",
    height: "50%",
    maxHeight: "90%",

    // height: theme.spacing(40),
    aspectRatio: 1,
    // opacity:Math.random(0.6,1)
  },
  fill: {
    width: "100%",
    height: "100%",
  },
  iosImage: {
    objectFit: "contain",
    borderRadius: "50%",
    width: "auto",
  },
  iosContainer: {
    width: "auto",
  },
}));

const UserAvatar = ({ user, size, style }) => {
  const [isIOS, setIsIOS] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    checkBrowser();
  }, []);
  const checkBrowser = () => {
    try {
      let is =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") == -1 &&
        navigator.userAgent.indexOf("FxiOS") == -1;
      setIsIOS(is);
      console.log("isIOS", isIOS);
    } catch (error) {}
  };

  console.log(
    "isIOS",
    isIOS || (RTCManager.isMob() && { img: classes.iosImage })
  );
  // return null;
  if (user.avatar) {
    return (
      <Avatar
        variant={size === "fill" || size === "square" ? "square" : "circular"}
        alt={user.name}
        classes={
          isIOS
            ? { img: classes.iosImage }
            : RTCManager.isMob() && { img: classes.iosImage }
        }
        className={`${
          classes[
            size === "square"
              ? "large"
              : size === "fill" && !user.avatar
              ? "large"
              : size
          ]
        } ${isIOS ? classes.iosContainer : ""}`}
        style={style}
        src={user.avatar}
      />
    );
  } else {
    return (
      <Avatar
        variant={size === "fill" || size === "square" ? "square" : "circular"}
        alt={user.name}
        classes={
          isIOS
            ? { img: classes.iosImage }
            : RTCManager.isMob() && { img: classes.iosImage }
        }
        className={`${
          classes[
            size === "square"
              ? "large"
              : size === "fill" && !user.avatar
              ? "large"
              : size
          ]
        } `}
        style={{
          ...style,
          backgroundColor: Helper.stringToColour(user.name),
          fontSize:
            size == "large" ? "20vmin" : size == "medium" ? "10vmin" : "20px",
          fontWeight: "bold",
        }}
        size={size}
      >
        {user.name[0]?.toUpperCase()}
      </Avatar>
    );
  }
};

export default React.memo(UserAvatar);

UserAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.string,
  style: PropTypes.object,
};

UserAvatar.defaultProps = {
  user: PropTypes.array,
  size: "circle",
  style: {},
};
