import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import RenderVideo from "./RenderVideo";
import { Card } from "@material-ui/core";

const LocalVideo = (props) => {
  const cardRef = useRef(null);
  const eventStore = useRef({
    isDragStarted: false,
    initX: 0,
    initY: 0,
    containerWidth: 0,
    containerHeight: 0,
    localVideoWidth: 0,
    localVideoHeight: 0,
    percLeft: 1,
    percTop: 1,
    padding: 8,
  });

  useEffect(() => {
    eventStore.current.padding = parseInt(
      getComputedStyle(document.body)
        .getPropertyValue("--padding-around-video")
        .match(/\d+/)[0]
    );
    cardRef.current.addEventListener("mousedown", (e) => {
      const isFlipped = e.target.className.indexOf("flipped-video") >= 0;
      eventStore.current.isDragStarted = true;
      eventStore.current.initX = isFlipped ? eventStore.current.localVideoWidth - e.offsetX : e.offsetX;
      eventStore.current.initY = e.offsetY;
    });

    cardRef.current.addEventListener("touchstart", (e) => {
      const bcr = e.target.getBoundingClientRect();
      eventStore.current.isDragStarted = true;
      eventStore.current.initX = e.targetTouches[0].clientX - bcr.x;
      eventStore.current.initY = e.targetTouches[0].clientY - bcr.y;
    });

    const drag = (e) => {
      if (e.touches?.[0]) {
        e = e.touches[0];
      }

      if (eventStore.current.isDragStarted) {
        let cx = e.clientX - eventStore.current.initX,
          cy = e.clientY - eventStore.current.initY;
        if (cx < eventStore.current.padding) {
          cx = eventStore.current.padding;
        }
        if (cy < eventStore.current.padding) {
          cy = eventStore.current.padding;
        }
        if (
          eventStore.current.containerWidth -
            e.clientX +
            eventStore.current.initX -
            eventStore.current.padding <
          eventStore.current.localVideoWidth
        ) {
          cx =
            eventStore.current.containerWidth -
            eventStore.current.localVideoWidth -
            eventStore.current.padding;
        }
        if (
          e.clientY >
          eventStore.current.containerHeight -
            eventStore.current.localVideoHeight +
            eventStore.current.initY
        ) {
          cy =
            eventStore.current.containerHeight -
            eventStore.current.localVideoHeight;
        }
        cardRef.current.style.left = cx + "px";
        cardRef.current.style.top = cy + "px";
      }
    };

    const dragStop = () => {
      if (eventStore.current.isDragStarted === false) return;
      eventStore.current.isDragStarted = false;
      if (cardRef.current.style.left) {
        eventStore.current.percLeft =
          (parseInt(cardRef.current.style.left.match(/\d+/)[0]) -
            eventStore.current.padding) /
          (eventStore.current.containerWidth -
            eventStore.current.localVideoWidth -
            eventStore.current.padding * 2);
      }
      if (cardRef.current.style.top) {
        eventStore.current.percTop =
          (parseInt(cardRef.current.style.top.match(/\d+/)[0]) -
            eventStore.current.padding) /
          (eventStore.current.containerHeight -
            eventStore.current.localVideoHeight -
            eventStore.current.padding);
      }
    };
    document.addEventListener("touchend", dragStop);
    document.addEventListener("mouseup", dragStop);
    document.addEventListener("mousemove", drag);
    document.addEventListener("touchmove", drag);

    const resizeObserver = new ResizeObserver((e) => {
      if(!cardRef.current) return
      if (cardRef.current.style.left) {
        const left =
          eventStore.current.percLeft *
            (e[0].target.offsetWidth -
              cardRef.current.offsetWidth -
              eventStore.current.padding * 2) +
          eventStore.current.padding;
        cardRef.current.style.left = `${left}px`;
      } else {
        cardRef.current.style.left = `${
          e[0].target.offsetWidth -
          cardRef.current.offsetWidth -
          eventStore.current.padding
        }px`;
      }
      if (cardRef.current.style.top) {
        const top =
          eventStore.current.percTop *
            (e[0].target.offsetHeight -
              cardRef.current.offsetHeight -
              eventStore.current.padding) +
          eventStore.current.padding;
        cardRef.current.style.top = `${top}px`;
      } else {
        cardRef.current.style.top = `${
          e[0].target.offsetHeight - cardRef.current.offsetHeight
        }px`;
      }
      eventStore.current.containerWidth = e[0].target.offsetWidth;
      eventStore.current.containerHeight = e[0].target.offsetHeight;
      eventStore.current.localVideoWidth = cardRef.current.offsetWidth;
      eventStore.current.localVideoHeight = cardRef.current.offsetHeight;
    });

    resizeObserver.observe(
      document.getElementsByClassName(props.boundaryClass || "spotlight-main-container")[0]
    );
    return () => {
     resizeObserver.disconnect()
    }
  }, []);

  return (
    <Card
      raised
      style={{ backgroundColor: "#00000000" }}
      className={"local-video-container"}
      ref={cardRef}
    >
      <RenderVideo
        size={
          props.avatarMode === "fullscreen"
            ? "fill"
            : props.avatarMode === "square"
            ? "square"
            : "small"
        }
        user={props.user}
      ></RenderVideo>
    </Card>
  );
};

export default LocalVideo;

LocalVideo.propTypes = {
  user: PropTypes.object,
};

LocalVideo.defaultProps = {
  user: {},
};
