/* eslint-disable */
import Logger from "../lib/Logger";

const ADD_LOCAL_TRACK = "ADD_LOCAL_TRACK";
const ADD_REMOTE_TRACK = "ADD_REMOTE_TRACK";
const CHANGE_READY_STATE = "CHANGE_READY_STATE";
const CHANGE_ROOM_NAME = "CHANGE_ROOM_NAME";
const CHANGE_AUDIO_STATE = "CHANGE_AUDIO_STATE";
const CHANGE_VIDEO_STATE = "CHANGE_VIDEO_STATE";
const CONNECTION_FAILED = "CONNECTION_FAILED";
const UPDATE_DOMINANT_VIDEO_ID = "UPDATE_DOMINANT_VIDEO_ID";
const REMOVE_REMOTE_TRACK = "REMOVE_REMOTE_TRACK";
const REMOVE_LOCAL_TRACKS = "REMOVE_LOCAL_TRACKS";
const REMOVE_REMOTE_TRACKS = "REMOVE_REMOTE_TRACKS";
const CHANGE_AUDIO_MODE = "CHANGE_AUDIO_MODE";
const ADD_REMOTE_VIDEO_TRACK = "ADD_REMOTE_VIDEO_TRACK";

const initialState = {
  localTracks: [],
  remoteTracks: [],
  remoteVideoTrack: null,
  ready: false,
  room: "",
  audioMute: false,
  audioSpeaker: false,
  videoMute: false,
  connection_failed: 0,
  dominantVideoId: null,
};
const TAG = "Tracks.js";
const Reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case REMOVE_REMOTE_TRACK:
      var id = action.payload;
      var index = state.remoteTracks.findIndex((track) => track.ownerId == id);

      if (index != -1) {
        var remoteTracks = [...state.remoteTracks];
        remoteTracks.splice(index, 1);
        const newUpdatedState = { ...state, remoteTracks };
        return newUpdatedState;
      }
      break;

    case REMOVE_LOCAL_TRACKS:
      newState.localTracks = initialState.localTracks;
      break;

    case REMOVE_REMOTE_TRACKS:
      newState.remoteTracks = initialState.remoteTracks;
      break;

    case UPDATE_DOMINANT_VIDEO_ID:
      var id = action.payload;
      newState.dominantVideoId = id;
      break;

    case ADD_LOCAL_TRACK:
      var localTracksData = action.payload.localTracks;
      if (localTracksData.length != 0) {
        newState.localTracks = localTracksData;
      }
      break;

    case ADD_REMOTE_TRACK:
      var remoteTracksData = action.payload.remoteTracks;
      var id = remoteTracksData.ownerEndpointId;
      var type = remoteTracksData.type;
      var index = state.remoteTracks.findIndex((track) => track.ownerId == id);
      if (index != -1) {
        var remoteTracks = [...state.remoteTracks];
        if (type == "video") {
          remoteTracks[index] = {
            ...remoteTracks[index],
            video: remoteTracksData,
          };
        } else {
          remoteTracks[index] = {
            ...remoteTracks[index],
            audio: remoteTracksData,
          };
        }
        const newUpdatedState = { ...state, remoteTracks };
        return newUpdatedState;
      } else {
        var track = {
          ownerId: id,
          audio: null,
          video: null,
        };

        if (type == "audio") {
          track.audio = remoteTracksData;
        } else {
          track.video = remoteTracksData;
        }
        newState.remoteTracks = [...state.remoteTracks, track];
      }
      break;

    case ADD_REMOTE_VIDEO_TRACK:
      newState.remoteVideoTrack = action.payload.remoteVideoTrack;
      break;

    case CHANGE_READY_STATE:
      newState.ready = action.payload.ready;
      break;

    case CHANGE_AUDIO_STATE:
      newState.audioMute = action.payload.audioMute;
      break;

    case CHANGE_AUDIO_MODE:
      newState.audioSpeaker = action.payload.audioMode;
      break;

    case CHANGE_VIDEO_STATE:
      newState.videoMute = action.payload.videoMute;
      break;

    case CHANGE_ROOM_NAME:
      newState.room = action.payload.room;
      break;

    case CONNECTION_FAILED:
      newState.connection_failed = action.payload.status;

    default:
      break;
  }
  return newState;
};

export default Reducer;
