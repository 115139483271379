const interpolateVal = (value, inputMin, inputMax, outputMin, outputMax) => {
  let result = value;

  if (outputMin == outputMax) {
    return outputMin;
  }

  if (inputMin == inputMax) {
    if (value <= inputMin) {
      return outputMin;
    }
    return outputMax;
  }

  return (
    outputMin +
    ((outputMax - outputMin) * (result - inputMin)) / (inputMax - inputMin)
  );
};

const findRangeIndex = (value, ranges) => {
  let index;
  for (index = 1; index < ranges.length - 1; index++) {
    if (ranges[index] >= value) {
      break;
    }
  }
  return index - 1;
};

const interpolate = (
  value,
  inputRange = [0, 0.1, 0.2, 0.5, 0.8, 1],
  outputRange = [0, 28, 38, 40, 44, 47]
) => {
  let rangeIndex = findRangeIndex(value, inputRange);
  return interpolateVal(
    value,
    inputRange[rangeIndex],
    inputRange[rangeIndex + 1],
    outputRange[rangeIndex],
    outputRange[rangeIndex + 1]
  );
};

export default interpolate;
