/* eslint-disable */
import React, { PureComponent, Component } from "react";
import VideoViewComponent from "./OvVideo";
import { PublicIcon, NetworkIcon } from "../../icons";
import GreenNetwork from "../../assets/Green-band.svg";
import YellowNetwork from "../../assets/yellow-band-medium.svg";
import OrangeNetwork from "../../assets/ThreeStrands-band.svg";
import RedNetwork from "../../assets/red-band-low.svg";
import "./UserVideo.css";
import RTCManager from "../../lib/RTCManager";
import AudioViewComponent from "./RTCAudio";
import Logger from "../../lib/Logger";
import AudioMuteSVG from "../../icons/mic_off.svg";
import VideoMuteSVG from "../../icons/videocam_off.svg";
import { isScreenSharingTrack } from "../../lib/utils";
import { Helper } from "../../lib/helper";
import AudioLevelIndicator from "../AudioLevelDots";
import PinIcon from "../../icons/pin.svg";
const TAG = "UserVideoComponent";
export default class UserVideoComponent extends Component {
  getNicknameTag() {
    // Gets the nickName of the user
    return this.props.name;
  }

  renderUserAvatar(user) {
    if (user.avatar) {
      return (
        <div className="main-video-avatar flex flex-col">
          {RTCManager.isMob() ? (
            <img
              className="aspect-ratio-1"
              style={{ width: "70%", borderRadius: "50%" }}
              src={user.avatar}
            />
          ) : (
            <img
              className="aspect-ratio-1"
              style={{ width: "70%", borderRadius: "50%" }}
              src={user.avatar}
            />
          )}

          <AudioLevelIndicator track={this.props.user.audioTrack} />
        </div>
      );
    } else {
      return (
        <div className="main-video-avatar flex flex-col">
          {RTCManager.isMob() ? (
            <img
              style={{ width: "70%", borderRadius: "50%" }}
              src={Helper.getSVGAvatar(
                this.props.user.name,
                this.props.user.name.substring(0, 1)
              )}
              alt={this.props.user.name}
            />
          ) : (
            <img
              style={{ width: "70%", borderRadius: "50%" }}
              src={Helper.getSVGAvatar(
                this.props.user.name,
                this.props.user.name.substring(0, 1)
              )}
              alt={this.props.user.name}
            />
          )}
          <AudioLevelIndicator track={this.props.user.audioTrack} />
        </div>
      );
    }
  }

  renderVideo = () => {
    if (
      (this.props.user.name && this.props.user.status === "inactive") ||
      this.props.user.status === "restoring" ||
      this.props.user.isVideoMuted ||
      !this.props.videoTrack ||
      this.props.user.showVideo === false ||
      (this.props.resolutions &&
        this.props.resolutions[this.props.user.id] &&
        Object.values(this.props.resolutions[this.props.user.id])[0].width ===
          0 &&
        Object.values(this.props.resolutions[this.props.user.id])[0].height ===
          0)
    ) {
      return this.renderUserAvatar(this.props.user);
    } else if (this.props.videoTrack) {
      return (
        <VideoViewComponent
          videoTrack={this.props.videoTrack}
          type={this.props.isVideoOn}
        />
      );
    }
  };

  _renderIcon() {
    return this.props.user.status;
    // if (
    //   this.props.connectionStatus ===
    //   window.JitsiMeetJS.constants.participantConnectionStatus.INACTIVE
    // ) {
    //   return <span className="connection_ninja">INACTIVE</span>;
    // }
  }

  render() {
    let userStats = this.props.user.stats;
    let networkColor = userStats
      ? userStats.connectionQuality < 25
        ? RedNetwork
        : userStats.connectionQuality < 50
        ? YellowNetwork
        : userStats.connectionQuality < 75
        ? OrangeNetwork
        : GreenNetwork
      : "";

    const { pinnedUserId } = this.props;
    Logger.log(TAG, "Pinned user id = ", pinnedUserId);
    return (
      <div
        onClick={() => this.props.onUserClicked(this.props.user)}
        className={`${
          RTCManager.isMob() ? "tile-container-mob" : "tile-container"
        } ${this.props.isSpotlight ? "" : "custom-tile-container"}`}
      >
        <div
          style={{
            textAlign: "left",
            fontSize:
              RTCManager.isMob() && this.props.orientation == "Portrait"
                ? "3vw"
                : "1vw",
          }}
          className="stream-component mob-font "
        >
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "6vw",
            }}
            className="custom-name"
          >
            {this.getNicknameTag()}
          </p>
        </div>
        <div className="stream-component user-icons">
          {this.props.isAudioOn ? null : (
            <img src={AudioMuteSVG} alt="Muted Autio" />
          )}
          {this.props.isVideoOn ? null : (
            <img src={VideoMuteSVG} alt="Muted Video" />
          )}
        </div>
        {!this.props.isLocalUser && (
          <AudioViewComponent audioTrack={this.props.audioTrack} />
        )}

        <div
          className={`thumb-video-container ${
            RTCManager.isMob() ? "thumb-mobile" : ""
          } `}
        >
          {pinnedUserId === this.props.user.id && (
            <div className="pin-wrapper">
              <img alt="pin" src={PinIcon} style={{ height: "25px" }} />
            </div>
          )}
          {this.renderVideo()}
          {/* {userStats && ( */}
          <div style={{ position: "absolute", bottom: 5, right: 5 }}>
            {networkColor ? (
              <img
                src={networkColor}
                style={{ height: "1.3vw", width: "1.3vw" }}
              />
            ) : null}

            {/* <NetworkIcon iconColor={networkColor} stats={userStats || {}} /> */}
          </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}
