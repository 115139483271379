import { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";

export const isScreenSharingTrack = (track) => {
  if (track && track.videoType === "desktop") {
    return true;
  }
  return false;
};

export const createLocalTracksfun = async (options) => {
  const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
  const micDeviceId = localStorage.getItem("lastAudioDeviceId");
  const config = cameraDeviceId && micDeviceId
  ? {
      ...options,
      cameraDeviceId,
      micDeviceId,
    }
  : cameraDeviceId
  ? {
      ...options,
      cameraDeviceId,
    }
  : micDeviceId
  ? {
      ...options,
      micDeviceId,
    }
  : options
  console.log('custom config', config);
  const track = await JitsiMeetJS.createLocalTracks(
    config
  );
  return track;
};

export const useIsMouseMoving = () => {
  const [value, setValue] = useState(false);
  useEffect(() => {
    const setFromEvent = (e) => {
      setValue(e);
    };
    const debouncedVersion = debounce(setFromEvent, 500, { leading: true });

    window.addEventListener("mousemove", debouncedVersion);
    return () => {
      window.removeEventListener("mousemove", debouncedVersion);
    };
  }, []);

  return value;
};

const isDebugMode = process.env.NODE_ENV === "development";
if (!isDebugMode) {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

export function useIntervals(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}
