import React from "react";
import RenderVideo from "./RenderVideo";
import PropTypes from "prop-types";
const Single = (props) => {
  let mainUser = props.user;

  return (
    <div className={"single-main-container"}>
      <RenderVideo size={"large"} withAudio user={mainUser} />
    </div>
  );
};

export default Single;
Single.propTypes = {
  user: PropTypes.object,
};

Single.defaultProps = {
  user: {},
};
