/* global  JitsiMeetJS */
/* eslint-disable */
import Logger from "./Logger";
import { Component } from "react";
import data from "../actions.js";
let connection = null;
let localTracks = [];
let isJoined = false;
let room = null;
let roomName = "Conference";

let remoteTrackListner;

let dispatch;

const remoteTracks = {};

const TAG = "RTCManager.js";
const options = {
  hosts: {
    domain: "rtc-namaste.cometchat.io",
    muc: "conference.rtc-namaste.cometchat.io",
  },
  bosh: "https://rtc-namaste.cometchat.io/http-bind",

  clientNode: "http://jitsi.org/jitsimeet",
};

// $(window).bind("beforeunload", unload);
// $(window).bind("unload", unload);

const confOptions = {
  openBridgeChannel: false,
};

const initOptions = {
  disableAudioLevels: true,

  // The ID of the jidesha extension for Chrome.
  desktopSharingChromeExtId: "mbocklcggfhnbahlnepmldehdhpjfcjp",

  // Whether desktop sharing should be disabled on Chrome.
  desktopSharingChromeDisabled: false,

  // The media sources to use when using screen sharing with the Chrome
  // extension.
  desktopSharingChromeSources: ["screen", "window"],

  // Required version of Chrome extension
  desktopSharingChromeMinExtVersion: "0.1",

  // Whether desktop sharing should be disabled on Firefox.
  desktopSharingFirefoxDisabled: false,
};

function makeConnection() {
  connection = new JitsiMeetJS.JitsiConnection(null, null, options);
  connection.connect();
  addEventListener();
}

function addEventListener() {
  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  connection.addEventListener(
    JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    onDisconnect
  );
}

function onConnectionSuccess() {
  Logger.log(TAG, "RoomName = ", roomName);
  room = connection.initJitsiConference(roomName, confOptions);
  room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
  room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoteTrackRemoved);
  room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
  room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
    Logger.log("User Joined ....");
    remoteTracks[id] = [];
  });
  room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
  room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
    Logger.log(`${track.getType()} - ${track.isMuted()}`);
  });
  room.on(
    JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
    (userID, displayName) => Logger.log(`${userID} - ${displayName}`)
  );
  room.on(
    JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
    (userID, audioLevel) => Logger.log(`${userID} - ${audioLevel}`)
  );
  room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () =>
    Logger.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`)
  );

  room.join();
}

function onRemoteTrackRemoved(track) {
  Logger.log(TAG, "onRemoteTrackRemoved-------- : ", track);

  dispatch(data.removeRemoteTrack(track.getParticipantId()));
}

function onConnectionFailed() {
  Logger.log("onConnectionFailed.....");
}

function onDisconnect() {
  Logger.log("onDisconnect called.....");
  connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    onConnectionSuccess
  );
  connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_FAILED,
    onConnectionFailed
  );
  connection.removeEventListener(
    JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    onDisconnect
  );
}

function onRemoteTrack(track) {
  Logger.log(TAG, "onRemoteTrack Called", track);
  if (track.isLocal()) {
    return;
  }
  const participant = track.getParticipantId();

  Logger.log(TAG, "Participant id = ", participant);
  Logger.log(TAG, "remoteTracks[participant] ? :", remoteTracks[participant]);

  if (!remoteTracks[participant]) {
    remoteTracks[participant] = [];
  }

  dispatch(data.addRemoteTracks(track));

  track.addEventListener(
    JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
    (audioLevel) => console.log(`Audio Level remote: ${audioLevel}`)
  );
  track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () =>
    console.log("remote track muted")
  );
  track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
    console.log("remote track stoped")
  );
  track.addEventListener(
    JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
    (deviceId) =>
      console.log(`track audio output device was changed to ${deviceId}`)
  );
}

function onConferenceJoined() {
  Logger.log("onConferenceJoined called ");
  isJoined = true;
  for (let i = 0; i < localTracks.length; i++) {
    room.addTrack(localTracks[i]);
  }
}

function onUserLeft(id) {
  Logger.log("onUserLeft called ", id);
  if (!remoteTracks[id]) {
    return;
  }
  dispatch(data.removeRemoteTrack(id));
}

function onLocalTracks(tracks) {
  Logger.log("onLocalTrack Called......");

  localTracks = tracks;
  dispatch(data.addLocalTracks(localTracks));
  for (let i = 0; i < localTracks.length; i++) {
    localTracks[i].addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
      (audioLevel) => console.log(`Audio Level local: ${audioLevel}`)
    );
    localTracks[i].addEventListener(
      JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
      () => console.log("local track muted")
    );
    localTracks[i].addEventListener(
      JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
      () => console.log("local track stoped")
    );
    localTracks[i].addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
      (deviceId) =>
        console.log(`track audio output device was changed to ${deviceId}`)
    );
    if (isJoined) {
      room.addTrack(localTracks[i]);
    }
  }
}

function unload() {
  if (localTracks.length > 0) {
    for (let i = 0; i < localTracks.length; i++) {
      localTracks[i].dispose();
    }
  }
  room.leave();
  connection.disconnect();
}

function disposeLocalVideoTrack() {
  if (localTracks.length > 0) {
    for (let i = 0; i < localTracks.length; i++) {
      if (localTracks[i].getType() === "video") {
        localTracks[i].dispose();
      }
    }
  }
}

export default class RTCManager {
  static init(sessionID) {
    roomName = sessionID;
    JitsiMeetJS.init(initOptions);
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    makeConnection();
  }

  static createLocalTracks(d) {
    Logger.log("createLocalTracks Called.....");
    dispatch = d;
    JitsiMeetJS.createLocalTracks({
      devices: ["audio", "video"],
      facingMode: "user",
    })
      .then(onLocalTracks)
      .catch((error) => {
        Logger.log("Create Local error..");
        throw error;
      });
  }

  static createScreenShareTrack() {
    disposeLocalVideoTrack();

    JitsiMeetJS.createLocalTracks({
      devices: ["desktop"],
    })
      .then(onLocalTracks)
      .catch((error) => {
        Logger.log("Create Local error..");
        throw error;
      });
  }

  static stopScreenShare() {
    disposeLocalVideoTrack();
    JitsiMeetJS.createLocalTracks({
      devices: ["audio", "video"],
      facingMode: "user",
    })
      .then(onLocalTracks)
      .catch((error) => {
        Logger.log("Create Local error..");
        throw error;
      });
  }

  static endCallSession() {
    unload();
  }

  static pauseVideo(pause) {
    if (pause) {
      localTracks[1].mute();
    } else {
      localTracks[1].unmute();
    }
  }

  static muteAudio(mute) {
    if (mute) {
      localTracks[0].mute();
    } else {
      localTracks[0].unmute();
    }
  }

  static isMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
