import React, { useEffect, useState } from "react";
import AudioViewComponent from "../RTCAudio";
import RenderVideo from "./RenderVideo";
import { Typography, Card, Badge } from "@material-ui/core";
import RTCManager from "../../../lib/RTCManager";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PropTypes from "prop-types";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import AudioLevelIndicator from "../Utils/AudioLevels";
import { ReactComponent as MicOffIcon } from "../assets/Mic-off-user.svg";
import AbsoluteView from "./AbsoluteView";
import { LocalizedString } from "../../../Utils/Constants";

const SideBar = ({
  unPinUser,
  pinUser,
  pinnedUserId,
  users,
  dominantSpeakerId,
  muteUser,
  peopleClicked,
  localizedObject,
  room,
  mainUser,
  myScreenShareID,
  avatarMode,
  connectionQuality,
  lastNId,
  stats,
  noisyMics = {},
  fitVideo,
  toggleFitVideo
}) => {
  const isMob = RTCManager.isMob();
  const [noOfUsers, setNoOfUsers] = useState(isMob ? 2 : 4);
  useEffect(() => {
    const calculateTiles = (box) => {
      console.log("box", box);

      // Check if user tiles are in a row
      const isTileRow = box.width > box.height;
      // Check the max number of tiles in a row because tile has min-width of 100
      const maxTiles = isTileRow ? Math.floor((box.width + 8) / 108) : Number.POSITIVE_INFINITY;

      let noOfUsers = Math.min(Math.ceil((isTileRow ? box.width / box.height : box.height / box.width) - 0.3), maxTiles);
      setNoOfUsers(noOfUsers - 1);
      document.querySelector(':root').style.setProperty('--no-of-tiles', noOfUsers.toString());
    }
    const observeElement = document.getElementById('side-bar-tile-list');
    if (!observeElement) {
      return;
    }
    const resizeObserver = new ResizeObserver(entries => {
      calculateTiles(entries[0].contentRect);
    });
    resizeObserver.observe(observeElement);
    return () => {
      resizeObserver.unobserve(observeElement);
    }
  }, []);
  // useEffect(() => {
  //   if (mainUser && mainUser.id) {
  //     let selectParticipantId = mainUser.id;
  //     if (selectParticipantId) {
  //       var constrains = new ConstraintFactory.getInstance();
  //       constrains.clear();
  //       constrains.setDefaultConstraints(180);
  //       constrains.setSelectedEndpoints(selectParticipantId);
  //       constrains.setStageEndPoints(selectParticipantId);
  //       constrains.setConstraints(selectParticipantId);
  //       room.setReceiverConstraints(constrains.getConstrains());
  //     }
  //   }
  // }, [mainUser]);
  /**
   * renderTiles function returns array of videocomponent component. maximum 5 videocomponent will be returned.
   * for the rest only audio component will be returned
   *@returns {array}
   */
  const renderTiles = () => {
    let userList = [];

    if (lastNId) {
      console.log("renderTiles ima in");
      for (let i = 1; i < users.length; i++) {
        if (lastNId.includes(users[i].id)) {
          userList.unshift({ ...users[i], inLastN: true });
        } else {
          userList.push(users[i]);
          console.log("out of last n user", users[i]);
        }
      }
      userList.unshift(users[0]);
    } else {
      userList = users;
    }

    let userPinned = false;

    return (
      <>
        {userList.map((item, index) => {
          if(item.id === pinnedUserId && users.length > noOfUsers + 1 && item.id !== users[0].id) {
            userPinned = true;
            return null;
          }
          if ((index - (userPinned ? 1 : 0)) > noOfUsers && item.id != myScreenShareID) {
            // TODO: What's the logic behind this.
            return (
              <AudioViewComponent key={item.id} audioTrack={item.audioTrack} />
            );
          }

          if (index === noOfUsers && index + 1 !== userList.length && isMob) {
            return (
              <div
                key={item.id}
                onClick={peopleClicked}
                className={"side-bar-tile-wrapper"}
              >
                <div className={"side-bar-tile-container"}>
                  <div className={"side-bar-tile-more-people-icon-container"}>
                    <Badge
                      className={"side-bar-tile-more-people-icon-badge"}
                      badgeContent={users.length - noOfUsers}
                      color="primary"
                    >
                      <PeopleOutlineIcon fontSize="medium" />
                    </Badge>
                  </div>
                  <Typography
                    className={"side-bar-tile-more-text"}
                    variant="h6"
                  >
                    {localizedObject["MORE"]}
                  </Typography>
                  {item.id !== myScreenShareID ? (
                    <AudioViewComponent audioTrack={item.audioTrack} />
                  ) : null}
                </div>
              </div>
            );
          }

          return (
            <div
              item={item.id}
              key={item.id}
              className={"side-bar-tile-wrapper"}
            >
              <div
                onClick={() => {
                  if (pinnedUserId == item.id) {
                    unPinUser();
                  } else {
                    pinUser(item);
                  }
                }}
                id="pin"
                className={"side-bar-tile-pin-icon-container"}
              >
                <PinIcon className={"side-bar-tile-pin-icon"} alt="Pin" />
              </div>
              <div className={"side-bar-tile-audio-icon"}>
                {item.isAudioMuted || !item.audioTrack ? (
                  <MicOffIcon
                    fontSize="medium"
                    className="red"
                    // style={}
                  />
                ) : (
                  <AudioLevelIndicator
                    noisy={noisyMics[item.id] === true}
                    track={item.audioTrack}
                  />
                )}
              </div>

              <RenderVideo
                connectionQuality={
                  connectionQuality && connectionQuality[item.id]
                }
                stats={stats}
                size={
                  avatarMode === "fullscreen"
                    ? "fill"
                    : avatarMode === "square"
                    ? "square"
                    : "small"
                }
                // resolutions={resolutions}
                cover
                withAudio={
                  (mainUser && item.id == mainUser.id) ||
                  myScreenShareID === item.id
                    ? false
                    : true && index !== 0
                }
                user={item}
              />
            </div>
          );
        })}
      </>
    );
  };

  if (!mainUser) {
    return null;
  }
  return (
    <div className={"side-bar"}>
      <div className={"side-bar-container"}>
        <div className={"side-bar-main-user-container"}>
          <div className={"side-bar-main-user-video"}>
            {console.log(
              "screensharecc  main",
              mainUser.name,
              myScreenShareID === mainUser.id ? false : true
            )}
            {myScreenShareID === mainUser.id ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  backgroundColor: "#000",
                  borderRadius: 8,
                }}
              >
                <p style={{ color: "#ffff" }}>
                  {localizedObject["SCREEN_SHARE_MSG"] ||
                    "You are currently sharing your screen."}
                </p>
              </div>
            ) : (
              <RenderVideo
                // resolutions={resolutions}
                size={
                  avatarMode === "fullscreen"
                    ? "fill"
                    : avatarMode === "square"
                    ? "square"
                    : "large"
                }
                withAudio={myScreenShareID === mainUser.id ? false : true}
                hideName
                user={mainUser}
                cover={mainUser.name?.includes(LocalizedString.SCREEN_SHARE_POSTFIX) ? false : fitVideo}
              />
            )}
            <AbsoluteView
              noisyMics={noisyMics}
              connectionQuality={
                connectionQuality && connectionQuality[mainUser.id]
              }
              user={mainUser}
              pinnedUserId={pinnedUserId}
              pinUser={pinUser}
              unPinUser={unPinUser}
              muteUser={muteUser}
              localizedObject={localizedObject}
              toggleFitVideo={toggleFitVideo}
              fitVideo={fitVideo}
            />
          </div>
        </div>
        <div id="side-bar-tile-list" className={"side-bar-tile-list"}>{renderTiles()}</div>
      </div>
    </div>
  );
};

export default React.memo(SideBar);

SideBar.propTypes = {
  unPinUser: PropTypes.func,
  pinUser: PropTypes.func,
  pinnedUserId: PropTypes.string,
  users: PropTypes.array,
  dominantSpeakerId: PropTypes.string,
  muteUser: PropTypes.func,
  peopleClicked: PropTypes.func,
  localizedObject: PropTypes.object,
  room: PropTypes.object,
  myScreenShareID: PropTypes.string,
  mainUser: PropTypes.object,
};

SideBar.defaultProps = {
  unPinUser: () => {},
  pinUser: () => {},
  pinnedUserId: "",
  users: [],
  dominantSpeakerId: "",
  muteUser: () => {},
  peopleClicked: () => {},
  localizedObject: {},
  room: {},
  myScreenShareID: "",
  mainUser: null,
};
