/* eslint-disable */
import React, { Component } from "react";
import MainVideoComponent from "./MainVideo";
import "./UserVideo.css";
import RTCManager from "../../lib/RTCManager";
import Logger from "../../lib/Logger";
import AudioViewComponent from "./RTCAudio";

import { isScreenSharingTrack } from "../../lib/utils";
const TAG = "MainVideoContainerComponent";
export default class MainVideoContainerComponent extends Component {
  getNicknameTag() {
    // Gets the nickName of the user
    return JSON.parse(this.props.streamManager.stream.connection.data)
      .clientData;
  }

  componentDidMount() {
    Logger.log(TAG, "Video Track value = ", this.props.videoTrack);
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <div className={"main-video-name custom-name"}>
          {this.props.username}
        </div>
        {this.props.videoTrack !== undefined ? (
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className={`main-video-container ${
                RTCManager.isMob() ? "Main-Video-Mob" : ""
              }`}
            >
              <MainVideoComponent
                streamManager={this.props.videoTrack}
                type={this.props.type}
              />
            </div>
            {/* <div className="stream-component">
                            <p>{this.getNicknameTag()}</p>
                        </div> */}
          </div>
        ) : null}
        {this.props.isBroadcast ? (
          <AudioViewComponent audioTrack={this.props.audioTrack} />
        ) : null}
        {this.props.isSingleMode ? (
          <AudioViewComponent audioTrack={this.props.audioTrack} />
        ) : null}
      </div>
    );
  }
}
