import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import AudioViewComponent from "../RTCAudio";
import RenderVideo from "./RenderVideo";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import LocalVideo from "./LocalVideo";
import { isScreenSharingTrack } from "../../../lib/utils";
import AbsoluteView from "./AbsoluteView";
import { LocalizedString } from "../../../Utils/Constants";

function Paginated(props) {
  const [selected, setSelected] = useState(0);
  const [lastNIds, setLastNIds] = useState([]);
  const [shouldUpdateLastN, setShouldUpdateLastN] = useState(true);

  const [numberOfPage, setNumberOfPage] = useState(1);
  const userIdAndScreenMapping = useRef(null);
  //to getthe current height and width of window
  const getWindowDimensions = React.useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    //the minHeight,minWidth of a grid is 150  so we divide it with screen height and width(removing padding and bottombutton height)
    if (props.showUserList) {
      width -= 350;
    }
    let numberOfRow = parseInt((height - 96) / 150);
    let numberOfCol = parseInt((width - 16) / 150);

    return {
      width,
      height,
      numberOfRow:
        numberOfRow > props.noOfUsersPerPage
          ? props.noOfUsersPerPage
          : numberOfRow || 1,
      numberOfCol:
        numberOfCol > props.noOfUsersPerPage
          ? props.noOfUsersPerPage
          : numberOfCol || 1,
    };
  }, [props.noOfUsersPerPage, props.showUserList]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const handleResize = useCallback(() => {
    let newDim = getWindowDimensions();
    if (
      windowDimensions.height != newDim.height ||
      windowDimensions.width != newDim.width
    )
      setWindowDimensions({ ...newDim });
  }, [props]);

  //when a user is pinned the slider need to be moved to first page
  useEffect(() => {
    setTimeout(() => setSelected(0), 1000);
  }, [props.pinnedUserId]);

  //adding a listener to detect screen size change
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    let newDim = getWindowDimensions();
    setWindowDimensions({ ...newDim });
  }, [props.noOfUsersPerPage, props.showUserList]);

  //updating constraint as the slider changes
  useEffect(() => {
    try {
      let constraintsObj = {};
      for (let i = 0; i < props.users.length; i++) {
        constraintsObj[props.users[i].id] = { maxHeight: 0 };
      }
      if (props.pinnedUserId && selected == 0) {
        constraintsObj[props.pinnedUserId] = { maxHeight: 720 };
        console.log(">>>", props.screenSharersID);

        if (props.screenSharersID?.length !== 0) {
          let screenSharerId = props.screenSharersID.find(
            (item) => item.screenShareId === props.pinnedUserId
          )?.userId;
          if (screenSharerId)
            constraintsObj[screenSharerId] = { maxHeight: 180 };
        }
        console.log(">>> constraintsObj", {
          lastN: -1,
          selectedEndpoints: [],
          onStageEndpoints: [props.pinnedUserId],
          defaultConstraints: { maxHeight: 0 },
          constraints: constraintsObj,
        });
        window.room.setReceiverConstraints({
          lastN: -1,
          selectedEndpoints: [],
          onStageEndpoints: [props.pinnedUserId],
          defaultConstraints: { maxHeight: 0 },
          constraints: constraintsObj,
        });
      } else {
        let currIndex = selected + (props.pinnedUserId ? 0 : 1);
        console.log("currIndex", currIndex);
        if (userIdAndScreenMapping.current[currIndex]) {
          let usersWhoCanGetVideo = [
            ...userIdAndScreenMapping.current[currIndex],
            // ...(userIdAndScreenMapping.current[selected - 1] || []),
            // ...(userIdAndScreenMapping.current[selected + 1] || []),
          ];
          for (let i = 0; i < usersWhoCanGetVideo.length; i++) {
            constraintsObj[usersWhoCanGetVideo[i]] = {
              maxHeight: 180,
            };
          }
          if (props.pinnedUserId) {
            constraintsObj[props.pinnedUserId] = {
              maxHeight: 720,
            };
          }

          console.log(">>> constraintsObj", {
            lastN: -1,
            selectedEndpoints: usersWhoCanGetVideo,
            onStageEndpoints: props.pinnedUserId ? [props.pinnedUserId] : [],
            defaultConstraints: { maxHeight: 0 },
            constraints: constraintsObj,
          });
          window.room.setReceiverConstraints({
            lastN: -1,
            selectedEndpoints: usersWhoCanGetVideo,
            onStageEndpoints: props.pinnedUserId ? [props.pinnedUserId] : [],
            defaultConstraints: { maxHeight: 0 },
            constraints: constraintsObj,
          });
        }
      }
    } catch (error) {
      console.log("error useEffect", error);
    }
  }, [
    selected,
    props.pinnedUserId,
    props.screenSharersID,
    userIdAndScreenMapping.current,
  ]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (props.dominantSpeakerList?.length) {
        setLastNIds([...props.dominantSpeakerList]);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [props.dominantSpeakerList]);

  const gridItem = (item, style, showVideo = true, mainVideo = false) => {
    return (
      <div
        key={item.id}
        style={style}
        className={` grid-box ${
          props.dominantSpeakerId === item.id ? "show-border" : ""
        } `}
      >
        <RenderVideo
          hideVideo={!showVideo}
          hideName
          size={
            props.avatarMode === "fullscreen"
              ? "fill"
              : props.avatarMode === "square"
              ? "square"
              : "medium"
          }
          withAudio={false}
          user={item}
          cover={!mainVideo || item.name?.includes(LocalizedString.SCREEN_SHARE_POSTFIX) ? false : props.fitVideo}
        />
        <AbsoluteView
          connectionQuality={props.connectionQuality && props.connectionQuality[item.id]}
          noisyMics={props.noisyMics}
          muteUser={props.muteUser}
          user={item}
          pinnedUserId={props.pinnedUserId}
          pinUser={props.pinUser}
          localizedObject={props.localizedObject}
          unPinUser={props.unPinUser}
          toggleFitVideo={props.toggleFitVideo}
          fitVideo={props.fitVideo}
          mainVideo={mainVideo}
        />
        {props.pinnedUserId == item.id ? null : (
          <div
            onClick={() => props.pinUser(item)}
            className="rounded-full pin-option"
          >
            <PinIcon alt="Pin" className="pin-image" />
          </div>
        )}
      </div>
    );
  };

  const getNoOfCol = (n) => {
    let noOfCol = 1;

    if (n > 2 && n <= 6) {
      noOfCol = 2;
    } else if (n > 6 && n <= 12) {
      noOfCol = 3;
    } else if (n > 12 && n <= 20) {
      noOfCol = 4;
    } else if (n > 20 && n <= 30) {
      noOfCol = 5;
    } else if (n > 30 && n <= 42) {
      noOfCol = 6;
    } else if (n > 42 && n <= 49) {
      noOfCol = 7;
    }

    return noOfCol;
  };

  const createGrid = (users, index) => {
    let numberOfRow = getNoOfCol(users.length);
    let numberOfCol = Math.ceil(users.length / numberOfRow);
    if (numberOfRow > props.noOfUsersPerPage) {
      numberOfRow = props.noOfUsersPerPage;
    }
    if (numberOfCol > props.noOfUsersPerPage) {
      numberOfCol = props.noOfUsersPerPage;
    }

    if (numberOfRow > windowDimensions.numberOfRow) {
      numberOfRow = windowDimensions.numberOfRow;
    }
    if (numberOfCol > windowDimensions.numberOfCol) {
      numberOfCol = windowDimensions.numberOfCol;
    }
    if (numberOfRow * numberOfCol < users.length) {
      numberOfRow = windowDimensions.numberOfRow;
      numberOfCol = windowDimensions.numberOfCol;
    }

    let finalGrid = [];
    for (let i = 0; i < numberOfRow; i++) {
      let currRow = [];

      for (let j = 0; j < numberOfCol; j++) {
        let currentIndex = i * numberOfCol + j;
        if (users[currentIndex]) {
          currRow.push(
            gridItem(
              users[currentIndex],
              {
                width: 100 / numberOfRow + "%",
                maxWidth: "40%",
                minWidth: "150px",
              },
              selected === index
            )
          );
        }
      }
      finalGrid.push(
        <div
          className="grid-row"
          style={{
            height: 100 / numberOfRow - 1 + "%",
            maxHeight: "50%",
            minHeight: "150px",
          }}
        >
          {currRow}
        </div>
      );
    }
    return (
      <div key={"page-" + index} className={"tile-main-container"}>
        <div
          style={{ paddingBottom: 20, paddingLeft: "5%", paddingRight: "5%" }}
          className={"tile-list-wrapper"}
        >
          {finalGrid}
        </div>
      </div>
    );
  };

  const carouselChild = () => {
    try {
      let arr = [];
      let users = props.users;
      let newUsers = [];
      let userIdsInPages = {};
      if (props.pinnedUserId) {
        userIdsInPages[0] = props.pinnedUserId;
        let pinnedUser = null;

        if (lastNIds.length) {
          let local = { ...users[0] };
          if (local.id == props.pinnedUserId) {
            pinnedUser = local;
          }
          let tempUserWithoutLocal = [...users];
          tempUserWithoutLocal.shift();
          let dominantUsers = [];
          let otherUsers = [];
          tempUserWithoutLocal.forEach((user) => {
            let index = lastNIds.findIndex((id) => id == user.id);
            if (props.pinnedUserId == user.id) {
              pinnedUser = user;
            } else if (index >= 0) {
              dominantUsers[index] = user;
            } else {
              otherUsers.push(user);
            }
          });

          newUsers = [local, ...dominantUsers, ...otherUsers];
        } else {
          newUsers = [...users].filter((item) => {
            if (item.id != props.pinnedUserId) {
              return true;
            } else {
              pinnedUser = item;
              return false;
            }
          });
        }
        if (pinnedUser) {
          let screenSharerId = props.screenSharersID.find(
            (item) => item.screenShareId === pinnedUser.id
          )?.userId;
          let screenShareUser =
            users[users.findIndex((item) => item.id === screenSharerId)];
          console.log(
            ">>> pinned User",
            pinnedUser,
            screenSharerId,
            screenShareUser
          );
          arr.push(
            <div
              key={"pinned"}
              className={`paginated-pinned-view ${
                props.pinnedUserId == props.myScreenShareID
                  ? "paginated-pinned-view-text"
                  : ""
              }`}
            >
              {props.pinnedUserId == props.myScreenShareID ? (
                <p style={{ color: "#ffff" }}>
                  You are currently sharing your screen.
                </p>
              ) : (
                gridItem(pinnedUser, { borderWidth: 0 }, true, true)
              )}
              {props.screenShareMode !== "presenter" &&
              isScreenSharingTrack(pinnedUser?.videoTrack) &&
              screenShareUser ? (
                <LocalVideo
                  user={screenShareUser}
                  boundaryClass={"paginated-pinned-view"}
                  avatarMode={false}
                />
              ) : null}
            </div>
          );
        } else {
          arr.push(null);
        }
      } else {
        // newUsers = [...users];
        if (lastNIds.length) {
          // for (let i = 1; i < users.length; i++) {
          //   if (lastNIds.includes(users[i].id)) {
          //     newUsers.unshift(users[i]);
          //   } else {
          //     newUsers.push(users[i]);
          //   }
          // }
          let local = { ...users[0] };
          let tempUserWithoutLocal = [...users];
          tempUserWithoutLocal.shift();
          // tempUserWithoutLocal.sort(
          //   (a, b) => lastNIds.indexOf(a.id) - lastNIds.indexOf(b.id)
          // );
          let dominantUsers = [];
          let otherUsers = [];
          tempUserWithoutLocal.forEach((user) => {
            let index = lastNIds.findIndex((id) => id == user.id);
            if (index >= 0) {
              dominantUsers[index] = user;
            } else {
              otherUsers.push(user);
            }
          });

          newUsers = [local, ...dominantUsers, ...otherUsers];
        } else {
          newUsers = [...users];
        }
      }
      newUsers = newUsers.filter((item) => item);
      let usersLength = newUsers.length;
      let numberOfUserPerScreen =
        windowDimensions.numberOfCol && windowDimensions.numberOfRow
          ? windowDimensions.numberOfCol * windowDimensions.numberOfRow
          : 9;
      for (let i = 0; i < usersLength / numberOfUserPerScreen; i++) {
        let tempUser = [...newUsers];
        let userList = tempUser.splice(
          i * numberOfUserPerScreen,
          numberOfUserPerScreen
        );
        userIdsInPages[i + 1] = userList
          .map((user) => user?.id)
          .filter((item) => item);
        arr.push(createGrid(userList, i + (props.pinnedUserId ? 1 : 0)));
      }
      if (arr.length != numberOfPage) {
        if (arr.length == 1) {
          setSelected(0);
        }
        if (selected > arr.length - 1) {
          setSelected(arr.length - 1);
        }
        setNumberOfPage(arr.length);
      }
      userIdAndScreenMapping.current = userIdsInPages;
      // for (let i = 0; i < 0; i++) {
      //   arr.push(
      //     <div
      //       style={{
      //         height: "100%",
      //         width: "100%",
      //         backgroundColor: "red",
      //         justifyContent: "center",
      //         alignItems: "center",
      //         color: "#fff",
      //       }}
      //     >
      //       {i}
      //     </div>
      //   );
      // }
      return arr;
    } catch (error) {
      console.log("error crosl child", error);
    }
  };

  const getAudio = () => {
    let arrOfAudioComponent = [];
    for (let i = 1; i < props.users.length; i++) {
      if (props.users[i].audioTrack && !props.users[i].isAudioMuted) {
        arrOfAudioComponent.push(
          <AudioViewComponent
            key={props.users[i].id + "_audio"}
            name={props.users[i].name}
            audioTrack={props.users[i].audioTrack}
          />
        );
      }
    }
    return arrOfAudioComponent;
  };
  return (
    <div className="paginated-container">
      {getAudio()}
      <AwesomeSlider
        // animation="cubeAnimation"
        selected={selected}
        bullets={numberOfPage > 1}
        mobileTouch={false}
        fillParent={true}
        infinite={false}
        onTransitionRequest={(e) => {
          setSelected(e.nextIndex);
        }}
      >
        {carouselChild()}
      </AwesomeSlider>
    </div>
  );
}

Paginated.propTypes = {
  avatarMode: PropTypes.string,
  unPinUser: PropTypes.func,
  pinUser: PropTypes.func,
  pinnedUserId: PropTypes.string,
  users: PropTypes.array,
  dominantSpeakerId: PropTypes.string,
  muteUser: PropTypes.func,
  localizedObject: PropTypes.object,
  myScreenShareID: PropTypes.string,
  localUserId: PropTypes.string,
  connectionQuality: PropTypes.object,
};

Paginated.defaultProps = {
  unPinUser: () => {},
  pinUser: () => {},
  pinnedUserId: "",
  users: [],
  dominantSpeakerId: "",
  muteUser: () => {},
  localizedObject: {},
  room: {},
  myScreenShareID: "",
  avatarMode: "circle",
  localUserId: "",
  connectionQuality: {},
};
export default Paginated;
