/* eslint-disable */
import React, { Component } from "react";
import "./UserVideo.css";
import Logger from "../../lib/Logger";
import { isScreenSharingTrack } from "../../lib/utils";

const TAG = "VideoViewComponent";
export default class VideoViewComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    // if (nextProps.videoTrack !== this.props.videoTrack) {
    //   return true;
    // }
    // if(nextProps.isVideoOn != this.props.isVideoOn){
    //   return true;
    // }
    // return false;

    return true;
  }

  componentDidUpdate(props) {
    if (props.videoTrack !== this.props.videoTrack && this.props.videoTrack) {
      this.props.videoTrack.attach(this.videoRef.current);
    }
  }

  componentDidMount() {
    if (this.props.videoTrack && !!this.videoRef) {
      this.props.videoTrack.attach(this.videoRef.current);
    }
  }

  render() {
    return (
      // <video
      //   autoPlay={true}
      //   ref={this.videoRef}
      //   style={{ borderRadius: "0.7rem" }}
      //   className={
      //     isScreenSharingTrack(this.props.videoTrack) ? "" : "rotate-180"
      //   }
      // />
      <video
        style={{ borderRadius: "0.7rem" }}
        autoPlay
        playsInline
        ref={this.videoRef}
      />
    );
  }
}
