/* eslint-disable */
import React, { PureComponent } from "react";
import Logger from "../../lib/Logger";
import { PublicIcon } from "../../icons";
import UserListComponent from "./UserListComponent";
import UserVideoComponent from "./UserVideoComponent";
import { ScrollView } from "@cantonjs/react-scroll-view";
import RTCManager from "../../lib/RTCManager";
import "./UserVideo.css";
import { Helper } from "../../lib/helper";
const TAG = "UserListComponent";
export default class TiledListComponent extends PureComponent {
  state = {
    showUsers: true,
  };

  onUserClicked = (user) => {
    Logger.log(TAG, "onUserClicked = ", user);

    if (!this.props.isBroadcast) {
      if (user.id === this.props.pinnedUserId) {
        this.props.unPinUser();
      } else {
        this.props.pinUser(user);
      }
    }
  };

  render() {
    const { pinnedUserId, pinUser, unPinUser, users } = this.props;
    return (
      <div
        className={`${
          RTCManager.isMob() && this.props.orientation
            ? "tile-list-container-portrait"
            : "tile-list-container"
        }
          ${
            this.state.showUsers
              ? "animated slide-out-left"
              : "animated slide-out-right"
          }
          
          hide-scroll
           custom-tile-list-container
        `}
      >
        {this.props.users.length > 2 ? (
          <div
            style={{
              width: RTCManager.isMob() ? "15vw" : "10vw",
              minHeight: "3vw",
              backgroundColor: "#000",
              marginBottom: 5,
              borderRadius: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 5,
                minHeight: "3vw",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: RTCManager.isMob() ? "column" : "row",
              }}
            >
              <div
                style={{
                  fontSize: RTCManager.isMob() ? "3vw" : "1.2vw",
                  fontWeight: "bold",
                  width: RTCManager.isMob() ? "8vw" : "2vw",
                  height: RTCManager.isMob() ? "8vw" : "2vw",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.props.users.length.toString()}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  [RTCManager.isMob() ? "marginTop" : "marginLeft"]: "5px",
                }}
              >
                <PublicIcon />
              </div>
            </div>
          </div>
        ) : null}
        {this.props.users.map((user, i) => {
          let userName = user.name;
          if (i === 0) {
            userName += " (you)";
          }

          return (
            <UserVideoComponent
              orientation={this.props.orientation}
              key={"UserVideoList" + i}
              user={user}
              onUserClicked={this.onUserClicked}
              name={userName}
              isLocalUser={i === 0}
              videoTrack={user.videoTrack}
              audioTrack={user.audioTrack}
              pinnedUserId={pinnedUserId}
              isAudioOn={!user.isAudioMuted}
              isVideoOn={!user.isVideoMuted}
              resolutions={this.props.resolutions}
            />
          );
        })}
      </div>
    );
  }
}
