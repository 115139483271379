import { IconButton, Tooltip } from "@material-ui/core";
// import CallEndIcon from "@material-ui/icons/CallEnd";
import { ReactComponent as CallEndIcon } from "../assets/End-call.svg";
import PropTypes from "prop-types";
import React from "react";
import debounce from "lodash.debounce";

const EndCallButton = (props) => {
  if (!props.isVisible) {
    return null;
  }
  return (
    <Tooltip title={props.localizedObject["END_CALL_TEXT"]} arrow>
      <IconButton
        size={"small"}
        component="span"
        className={"bottom-buttons-icon-button"}
        onClick={debounce((e) => {
          e.stopPropagation();
          props.onPress();
        }, 1000)}
      >
        <div
          style={{ backgroundColor: "#d93438" }}
          className={"bottom-buttons-icon-container"}
        >
          <CallEndIcon className={"bottom-buttons-icon-color"} />
        </div>
      </IconButton>
    </Tooltip>
  );
};

EndCallButton.defaultProps = {
  onPress: () => {},
  localizedObject: {},
  isVisible: true,
};

EndCallButton.propTypes = {
  onPress: PropTypes.func,
  localizedObject: PropTypes.object,
  isVisible: PropTypes.bool,
};

export default EndCallButton;
