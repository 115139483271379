import React from "react";
import AudioViewComponent from "../RTCAudio";
import RenderVideo from "./RenderVideo";
import PropTypes from "prop-types";
// import PinIcon from "../Pin.png";

import AbsoluteView from "./AbsoluteView";
import { LocalizedString } from "../../../Utils/Constants";

const Spotlight = (props) => {
  // const [mainUser, setMainUser] = useState(null);

  // //this is the logic by which the spotlight user is determined
  // //the prefrence should be give to pinned user then dominentspeaker and if there no one then the local user
  // useEffect(() => {
  //   let dummyMainUser = props.users[0];
  //   if (props.dominantSpeakerId && !props.pinnedUserId) {
  //     dummyMainUser = props.users.find(
  //       (user) => user.id === props.dominantSpeakerId
  //     );
  //   }
  //   if (props.pinnedUserId) {
  //     dummyMainUser = props.users.find(
  //       (user) => user.id === props.pinnedUserId
  //     );
  //   }
  //   setMainUser(dummyMainUser);
  //   if (dummyMainUser && dummyMainUser.id) {
  //     props.room.selectParticipant(dummyMainUser.id);
  //   }
  // }, [props.users, props.dominantSpeakerId, props.pinnedUserId]);

  // useEffect(() => {
  //   if (!mainUser) {
  //     if (props.users[0]) {
  //       setMainUser(props.users[0]);
  //     }
  //   }
  // }, [mainUser]);

  if (!props.mainUser) {
    return null;
  }

  /**
   * addOtherAudio function returns array of audio component of all the remainingUser.
   *@returns {array}
   */
  const addOtherAudio = () => {
    let audioArr = props.users.map((item) => {
      if (
        item.id !== props.mainUser.id &&
        !item.isLocalUser &&
        item.id !== props.myScreenShareID
      ) {
        return <AudioViewComponent audioTrack={item.audioTrack} />;
      }
    });
    return audioArr;
  };
  return (
    <div className={"spotlight-main-container"}>
      {/* {props.mainUser.id === props.pinnedUserId ? (
        <div
          onClick={() => {
            props.unPinUser();
          }}
        >
          <PinIcon className={"side-bar-main-user-video-pin-icon"} alt="Pin" />
        </div>
      ) : null} */}
      {props.myScreenShareID === props.mainUser.id ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#000",
            borderRadius: 8,
            color: "#fff",
          }}
        >
          {props.localizedObject["SCREEN_SHARE_MSG"] ||
            "You are currently sharing your screen."}
        </div>
      ) : (
        <RenderVideo
          size={
            props.avatarMode === "fullscreen"
              ? "fill"
              : props.avatarMode === "square"
              ? "square"
              : "large"
          }
          withAudio={props.myScreenShareID != props.mainUser.id}
          hideName
          user={props.mainUser}
          cover={props.mainUser.name?.includes(LocalizedString.SCREEN_SHARE_POSTFIX) ? false : props.fitVideo}
        />
      )}
      {addOtherAudio()}
      <AbsoluteView
        noisyMics={props.noisyMics}
        connectionQuality={
          props.connectionQuality && props.connectionQuality[props.mainUser.id]
        }
        user={props.mainUser}
        pinnedUserId={props.pinnedUserId}
        pinUser={props.pinUser}
        unPinUser={props.unPinUser}
        muteUser={props.muteUser}
        toggleFitVideo={props.toggleFitVideo}
        fitVideo={props.fitVideo}
      />
    </div>
  );
};

export default Spotlight;

Spotlight.propTypes = {
  users: PropTypes.array,
  dominantSpeakerId: PropTypes.string,
  pinnedUserId: PropTypes.string,
  unPinUser: PropTypes.func,
  muteUser: PropTypes.func,
  pinUser: PropTypes.func,
  room: PropTypes.object,
  myScreenShareID: PropTypes.string,
  mainUser: PropTypes.object,
  localizedObject: PropTypes.object,
  connectionQuality: PropTypes.object,
  fitVideo: PropTypes.bool.isRequired,
  toggleFitVideo: PropTypes.func.isRequired,
};
Spotlight.defaultProps = {
  users: [],
  dominantSpeakerId: "",
  pinnedUserId: "",
  unPinUser: () => {},
  pinUser: () => {},
  muteUser: () => {},
  room: {},
  myScreenShareID: "",
  mainUser: {},
  localizedObject: {},
  connectionQuality: {},
};
