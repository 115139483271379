/* eslint-disable */
import React, { Component } from "react";

const AUDIO_LEVEL_DOTS = 3;

const CENTER_DOT_INDEX = Math.floor(AUDIO_LEVEL_DOTS / 2);

class AudioLevelIndicator extends Component {
  state = {
    audioLevel: 0,
  };
  _isMounted = false;

  setAudiolevel = (audioLevel) => {
    if (!this._isMounted) return;
    if (this.props.track && !this.props.track.isMuted()) {
      this.setState({ audioLevel });
    }
  };
  componentDidMount() {
    this._isMounted = true;
    if (this.props.track) {
      this.props.track.on(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.track !== prevProps.track &&
      prevProps.track &&
      this.props.track
    ) {
      prevProps.track.off(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );

      this.props.track.on(
        window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        this.setAudiolevel
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // First make sure we are sensitive enough.
    const audioLevel = Math.min(this.state.audioLevel * 1.2, 1);
    // Let's now stretch the audio level over the number of dots we have.
    const stretchedAudioLevel = AUDIO_LEVEL_DOTS * audioLevel;

    const audioLevelDots = [];

    for (let i = 0; i < AUDIO_LEVEL_DOTS; i++) {
      const distanceFromCenter = CENTER_DOT_INDEX - i;
      const audioLevelFromCenter =
        stretchedAudioLevel - Math.abs(distanceFromCenter);
      const cappedOpacity = Math.min(1, Math.max(0, audioLevelFromCenter));
      let className;

      if (distanceFromCenter === 0) {
        className = "audiodot-middle";
      } else if (distanceFromCenter < 0) {
        className = "audiodot-top";
      } else {
        className = "audiodot-bottom";
      }

      audioLevelDots.push(
        <span
          className={className}
          key={i}
          style={{ opacity: cappedOpacity }}
        />
      );
    }

    return <span className="audioindicator in-react">{audioLevelDots}</span>;
  }
}

export default AudioLevelIndicator;
