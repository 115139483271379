import { IconButton, Tooltip, Badge } from "@material-ui/core";
import { ReactComponent as MicOnIcon } from "../assets/Micon-bottom.svg";
import { ReactComponent as MicOffIcon } from "../assets/Micoff-bottom.svg";
import PropTypes from "prop-types";
import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import debounce from "lodash.debounce";
const AudioButton = (props) => {
  /**
   * getIcon function simple returns the icon based on current audio state
   *@returns {Icon}
   */
  const getIcon = () => {
    if (props.isMuted) {
      return (
        <MicOffIcon fontSize="medium" className={"bottom-buttons-icon-color"} />
      );
    } else {
      return <MicOnIcon className={"bottom-buttons-icon-color"} />;
    }
  };

  //this is a check to make sure that do we need to render the audiobutton
  //it can be hidden when we cannot create a audio track or when developer whats to hide it
  if (props.isVisible) {
    return (
      <Tooltip
        disableHoverListener={props.noTrack}
        title={
          props.isMuted
            ? props.localizedObject["UN_MUTE_AUDIO_TEXT"]
            : props.localizedObject["MUTE_AUDIO_TEXT"]
        }
        arrow
      >
        <IconButton
          id={"audioButton"}
          aria-label="delete"
          size={"small"}
          component="span"
          className={"bottom-buttons-icon-button"}
          onClick={debounce((e) => {
            e.stopPropagation();
            props.onPress();
          }, 500)}
        >
          <div
            style={{ backgroundColor: props.isMuted ? "#d93438" : "#31383E" }}
            className={"bottom-buttons-icon-container"}
          >
            {getIcon()}
          </div>
          {props.noTrack ? (
            <Tooltip title={props.localizedObject["MIC_ERROR_MSG"]} arrow>
              <div className="track-error-container">
                <WarningIcon
                  style={{
                    aspectRatio: 1,
                    height: 16,
                    width: 16,
                    color: "#fff",
                  }}
                />
              </div>
            </Tooltip>
          ) : null}
        </IconButton>
      </Tooltip>
    );
  } else {
    return null;
  }
};

AudioButton.defaultProps = {
  isMuted: true,
  onPress: () => {},
  isVisible: false,
  localizedObject: {},
  noTrack: false,
};

AudioButton.propTypes = {
  isMuted: PropTypes.bool,
  isVisible: PropTypes.bool,
  localizedObject: PropTypes.object,
  onPress: PropTypes.func,
  noTrack: PropTypes.bool,
};

export default AudioButton;
