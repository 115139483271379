/* eslint-disable */
import React from "react";
import { Helper } from "../../lib/helper";
import AudioMuteSVG from "../../icons/mic_off.svg";
import AudioUnmuteSVG from "../../icons/mute_user.svg";
import OfflineSVG from "../../icons/interrupted.svg";
import AudioViewComponent from "./RTCAudio";
import VideoMuteSVG from "../../icons/videocam_off.svg";
import AudioLevelIndicator from "../AudioLevelDots";
import GreenNetwork from "../../assets/Green-band.svg";
import YellowNetwork from "../../assets/yellow-band-medium.svg";
import OrangeNetwork from "../../assets/ThreeStrands-band.svg";
import RedNetwork from "../../assets/red-band-low.svg";
import ReactTooltip from "react-tooltip";
import PinIcon from "../../icons/pin.svg";
const UserSidebar = ({
  index: i,
  user,
  firstUser,
  onUserClicked,
  pinnedUserId,
  onMuteParticipant,
}) => {
  const userName = user.name;
  let userStats = user.stats;
  let networkColor = userStats
    ? userStats.connectionQuality < 25
      ? RedNetwork
      : userStats.connectionQuality < 50
      ? YellowNetwork
      : userStats.connectionQuality < 75
      ? OrangeNetwork
      : GreenNetwork
    : "";
  return (
    <div className="flex items-center">
      {firstUser.id !== user.id && (
        <div
          style={{
            display: "inline",
            width: 0,
          }}
        >
          {" "}
          <AudioViewComponent audioTrack={user.audioTrack} />
        </div>
      )}

      <button
        onClick={() => onUserClicked(user)}
        className={
          "bg-gray-900 p-5 border-none shadow-lg mb-3 mx-3 rounded-md block w-full outline-none focus:outline-none relative video-tiles " +
          (pinnedUserId === user.id ? "bg-gray-500" : "") +
          (user.status === "interrupted" ? "opacity-50" : "")
        }
      >
        {/* <div
              className="user-stats"
              style={{ bottom: 0, top: "100%" }}
            >
              <NetworkIcon
                stats={getConnectivityStatsForUser(user.id, stats)}
              />
            </div> */}
        <div className="flex flex-row justify-between items-center">
          <div style={{ alignItems: "center" }} className="flex items-start">
            <div className="flex flex-col">
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <img
                  style={{ height: "30px", borderRadius: "50%" }}
                  src={Helper.getSVGAvatar(userName, userName.substring(0, 1))}
                  alt={userName}
                />
              </div>
            </div>
            <div
              style={{ alignItems: "center", width: "100px" }}
              className="flex ml-2"
            >
              <div
                data-tip={userName}
                style={{ "text-overflow": "ellipsis", overflow: "hidden" }}
                className="font-bold text-sm capitalize"
              >
                {userName}
                {i === 0 && <small className="text-gray-600"> (You) </small>}
              </div>
              <ReactTooltip place="top" type="dark" effect="float" />
              {!user.audioTrack?.isMuted() ? (
                <AudioLevelIndicator track={user.audioTrack} />
              ) : null}
            </div>
          </div>
          <div style={{ alignItems: "center" }} className="flex items-start">
            <img
              src={AudioUnmuteSVG}
              onClick={(e) => {
                e.stopPropagation();
                onMuteParticipant(user);
              }}
              title="Mute Participant"
              alt="Mute Participant"
              className={`mr-2 rounded-full p-1 bg-gray-700 ${
                user.id === firstUser.id ||
                (user.isAudioMuted && user.status !== "interrupted")
                  ? "hidden"
                  : "block"
              }`}
            />
            <img
              src={AudioMuteSVG}
              alt="Muted Autio"
              className={`mr-2 bg-red-600 rounded-full p-1 ${
                user.isAudioMuted && user.status !== "interrupted"
                  ? "block"
                  : "hidden"
              }`}
            />

            <img
              className={`mr-2 bg-red-600 rounded-full p-1 ${
                user.isVideoMuted && user.status !== "interrupted"
                  ? "visible"
                  : "invisible"
              }`}
              src={VideoMuteSVG}
              alt="Muted Video"
            />

            <img
              src={OfflineSVG}
              alt="Connection Interrupted"
              className={`mr-2 bg-red-600 rounded-full p-1 ${
                user.status === "interrupted" ? "block" : "hidden"
              }`}
            />
            {networkColor ? (
              <img src={networkColor} style={{ height: 17, margin: 8 }} />
            ) : null}
            <img
              alt="Pinned user"
              src={PinIcon}
              style={{ height: "25px", width: "25px" }}
              className={`mr-4 bg-blue-600 rounded-full p-1 ${
                pinnedUserId === user.id ? "visible" : "invisible"
              }`}
            />
          </div>
        </div>
      </button>
    </div>
  );
};

export default UserSidebar;
