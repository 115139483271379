import { Typography } from "@material-ui/core";
import React from "react";
import { toPascalCase } from "../../../lib/helper";
import MainVideoComponent from "../MainVideo";
import AudioViewComponent from "../RTCAudio";
import UserAvatar from "../Utils/UserAvatar";

const RenderVideo = (props) => {
  if (!props.user) {
    return null;
  }

  if (
    props.user.isVideoMuted ||
    !props.user.videoTrack ||
    props.user.status != "active" || 
    props.hideVideo
  ) {
    return (
      <div className={"video-container"}>
        <UserAvatar size={props.size} user={props.user}></UserAvatar>
        <div className={"video-container-user-name"}>
          {props.hideName ? (
            props.hideVideo
          ) : (
            <Typography
              noWrap={false}
              variant={"h6"}
              className={"video-container-user-name-text"}
            >
              {toPascalCase(props.user.name)}
            </Typography>
          )}
        </div>

        {props.user.audioTrack && props.withAudio && !props.user.isLocalUser ? (
          <AudioViewComponent
            name={props.user.name}
            audioTrack={props.user.audioTrack}
          />
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={"video-container"}>
        <MainVideoComponent
          flipped={props.user.isLocalUser}
          name={props.user.name}
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            objectFit: props.cover ? "cover" : "contain",
            borderRadius: 8,
          }}
          streamManager={props.user.videoTrack}
        />

        {props.withAudio &&
        !props.user.isLocalUser &&
        props.user.audioTrack &&
        !props.user.isAudioMuted ? (
          <AudioViewComponent
            name={props.user.name}
            audioTrack={props.user.audioTrack}
          />
        ) : null}
        <div className={"video-container-user-name"}>
          {props.hideName ? null : (
            <Typography
              variant={"h6"}
              className={"video-container-user-name-text"}
            >
              {toPascalCase(props.user.name)}
            </Typography>
          )}
        </div>
        {/* <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
            color: "#fff",
          }}
        >
          {props.user.id || ""}
          <div>
            {props.stats
              ? props.stats?.resolution?.[props.user.id] &&
                Object.values(props.stats?.resolution?.[props.user.id])?.[0]
                  ?.width +
                  " X " +
                  Object.values(props.stats?.resolution?.[props.user.id])?.[0]
                    ?.height
              : null}
          </div>
          <div>
            {props.stats
              ? props.stats?.framerate?.[props.user.id] &&
                Object.values(props.stats?.framerate?.[props.user.id])?.[0]
              : null}
          </div>
        </div> */}
      </div>
    );
  }
};

export default React.memo(RenderVideo);
