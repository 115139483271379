/* eslint-disable */
import React, { useState } from "react";
import { PublicIcon } from "../../icons";
import { useStatsStore, getConnectivityStatsForUser } from "../store/stats";
import UserSidebar from "./UserSidebar";
import Logger from "../../lib/Logger";
const TAG = "UserListComponent";
import ArrowIcon from "../../icons/arrow.svg";
export default function UserListComponent(props) {
  const [showUsers, setShowUsers] = useState(true);
  const { pinnedUserId, users } = props;
  const stats = useStatsStore((state) => state.stats);

  let activeUsers = props.users.filter(
    (user) => user.audioTrack || user.videoTrack
  );

  const localUser = users[0];
  const localUserStats = getConnectivityStatsForUser(localUser.id, stats);

  const onUserClicked = (user) => {
    Logger.log(TAG, "onUserClicked : ", user);
    if (user.id === pinnedUserId) {
      props.unPinUser();
    } else {
      props.pinUser(user);
    }
  };
  const onMuteParticipant = (user) => {
    props.onMuteParticipant(user);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      {/* {localUser && (
        <div
          className="local-video-stream video-tiles"
          style={{
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <div className="user-stats">
            <NetworkIcon stats={localUserStats} />
          </div>
          <VideoViewComponent videoTrack={localUser.videoTrack} />
        </div>
      )} */}
      {showUsers ? null : (
        <button onClick={() => setShowUsers(!showUsers)}>
          <div
            className="bg-gray-900 p-2"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PublicIcon />
            &nbsp;
            <span style={{ color: "white" }}>{props.users.length}</span>
            &nbsp; &nbsp;
          </div>
        </button>
      )}
      <div
        className={
          showUsers ? "animated slide-out-left" : "animated slide-out-right"
        }
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          overflowY: "auto",
          maxHeight: "calc(100vh - 11vw)",
        }}
      >
        <div
          className=" h-full text-gray-200 bg-gray-800 rounded-md"
          style={{ width: "350px" }}
        >
          <div className="flex justify-between p-5 pt-3 pb-3 items-center">
            <h5 className="font-bold justify-center flex">
              {props.localizedObject["PEOPLE_TEXT"]} ({props.users.length}){" "}
            </h5>

            <button onClick={() => setShowUsers(!showUsers)}>
              <img
                alt="toggle user tiles"
                src={ArrowIcon}
                style={{ height: "28px" }}
                className={showUsers ? "rotate-180" : ""}
              />
            </button>
          </div>
          <p className="text-justify mt-0 ml-4 mr-4 mb-4 text-sm font-normal leading-5 text-gray-400 ">
            {props.localizedObject["SPOTLIGHT_DETAIL_TEXT1"]}{" "}
            <span className="font-bold italic text-highlight-blue">
              {props.localizedObject["SPOTLIGHT_DETAIL_TEXT2"]}
            </span>{" "}
            {props.localizedObject["SPOTLIGHT_DETAIL_TEXT3"]}
            <span className="font-bold italic text-highlight-blue">
              &nbsp;{props.localizedObject["SPOTLIGHT_DETAIL_TEXT4"]}
            </span>
            .
          </p>
          {props.users.map((user, i) => {
            return (
              <UserSidebar
                index={i}
                key={i}
                user={user}
                pinnedUserId={pinnedUserId}
                firstUser={users[0]}
                onUserClicked={onUserClicked}
                onMuteParticipant={onMuteParticipant}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
