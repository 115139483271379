import { Card, Typography, Badge } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import AudioViewComponent from "../RTCAudio";
import DisplayUserNameWithOption from "../Utils/DisplayName";
import RenderVideo from "./RenderVideo";
import PropTypes from "prop-types";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { ReactComponent as PinIcon } from "../assets/pin.svg";

import ConstraintFactory from "../Model/Constraints";
const TAG = "TileMode";
let renderNum = 0;
const TileMode = (props) => {
  /**
   * getNoOfCol will return the number of coloumn we should create for grid layout
   * depending uplon the number of user
   * @return {number}
   */
  // const [constraints, setConstraints] = useState(null);

  //const {pinnedUserId,dominantSpeakerId } = props
  // const [pinnedUserId, setPinnedUserId] = useState(props.pinnedUserId);
  // const [usersListSize, setUsersListSize] = useState(0);
  // const [dominantSpeakerId, setDominantSpeakerId] = useState(
  //   props.dominantSpeakerId
  // );
  // const prevUsers = usePrevious(props.users);
  // const prevPinnedUserID = usePrevious(props.pinnedUserId);
  // const prevDominantSpeakerID = usePrevious(props.dominantSpeakerId)

  // useEffect(() => {
  //   setPinnedUserId(props.pinnedUserId);
  //   if (props.users) setUsersListSize(props.users.length);
  //   setDominantSpeakerId(props.dominantSpeakerId);
  // }.[]);

  // useEffect(() => {
  //   console.log(TAG, "UseEffect Pinned user = ", pinnedUserId);
  //   console.log(TAG, "UseEffect Dominant user = ", dominantSpeakerId);
  //   console.log(TAG, "UseEffect UserListSize = ", usersListSize);

  //   //var constrains = {}
  //   // var constrains = new ConstraintFactory.getInstance();

  //   // if (pinnedUserId) {
  //   //   constrains.clear();
  //   //   constrains.setDefaultConstraints(180);
  //   //   constrains.setSelectedEndpoints(pinnedUserId);
  //   //   constrains.setStageEndPoints(pinnedUserId);
  //   //   constrains.setConstraints(pinnedUserId);
  //   // } else {
  //   //   constrains.clear();
  //   //   // if (usersListSize < 5) {
  //   //   //   constrains.setDefaultConstraints(720);
  //   //   // } else if (usersListSize >= 6 && usersListSize <= 14) {
  //   //   //   constrains.setDefaultConstraints(360);
  //   //   // } else if (usersListSize >= 15) {
  //   //   //   constrains.setDefaultConstraints(180);
  //   //   // }
  //   //   constrains.setDefaultConstraints(180);
  //   // }
  //   // console.log(TAG, "Constraints =", constrains.getConstrains());
  //   // setConstraints(constrains.getConstrains());
  // }, [usersListSize, pinnedUserId, dominantSpeakerId]);

  // useEffect(() => {
  //   console.log(TAG, "UseEffect constrains = ", constraints);
  //   if (constraints) {
  //     props.room.setReceiverConstraints(constraints);
  //   }
  // }, [constraints]);

  const getNoOfCol = () => {
    let n = props.users.length;
    let noOfCol = 1;
    if (n <= 2) {
      noOfCol = 1;
    } else if (n > 2 && n <= 6) {
      noOfCol = 2;
    } else if (n > 6 && n <= 12) {
      noOfCol = 3;
    } else if (n > 12 && n <= 20) {
      noOfCol = 4;
    } else if (n > 20 && n <= 30) {
      noOfCol = 5;
    } else if (n > 30 && n <= 42) {
      noOfCol = 6;
    } else {
      noOfCol = 7;
    }

    if (props.noOfUsersInTile * props.noOfUsersInTile < n) {
      return props.noOfUsersInTile;
    }
    return noOfCol;
  };

  // const setGridConstraints = (users,pinnedUserId,dominantSpeakerId) => {
  //   let dummyMainUser = props.users[0];
  //   console.log(TAG,"UserList size = ",props.users.length);
  //   if (props.dominantSpeakerId && !props.pinnedUserId) {
  //     dummyMainUser = props.users.find(
  //       (user) => user.id === props.dominantSpeakerId
  //     );
  //   }
  //   if (props.pinnedUserId) {
  //     dummyMainUser = props.users.find(
  //       (user) => user.id === props.pinnedUserId
  //     );
  //   }

  //   if (dummyMainUser && dummyMainUser.id) {
  //       let  selectParticipantId = dummyMainUser.id
  //       if(selectParticipantId){

  //         var constrains = {}
  //         constrains.selectedEndpoints = [selectParticipantId]
  //         constrains.onStageEndpoints = [selectParticipantId]

  //         // constrains["constraints"] = {};
  //         // constrains["constraints"][selectParticipantId] = {'maxHeight':720}
  //         // console.log("receiver constrains = ",constrains);

  //         if(props.users.length <=5){
  //           constrains.lastN = 5
  //           constrains.defaultConstraints = { 'maxHeight': 360 }
  //         }else if(props.users.length >=6 && props.users.length <=15){
  //           constrains.lastN = 15
  //           constrains.defaultConstraints = { 'maxHeight': 180}
  //         }else if(props.users.length >=16){
  //           constrains.lastN = 10
  //           constrains.defaultConstraints = { 'maxHeight': 180}
  //         }
  //         setConstraints(constrains)
  //       }
  //   }
  // }

  /**
   * renderGridMode this function create the grid layout it calculate how many rows and column are required
   * to areage the the given users and returns a ui acordingly
   * @return {component}
   */
  const renderGridMode = () => {
    let { users, pinnedUserId, dominantSpeakerId, localUserId } = props;

    if (pinnedUserId) {
      return pinnedUI();
    }

    let numberOfCol = getNoOfCol();
    let numberOfRow = Math.ceil(users.length / numberOfCol);
    if (numberOfRow > props.noOfUsersInTile) {
      numberOfRow = props.noOfUsersInTile;
    }
    let colView = [];
    let isNoOfUserGreater =
      props.noOfUsersInTile * props.noOfUsersInTile < props.users.length;
    let lastNumber = 0;
    let lastN = null;
    if (props.lastNId.length && users.length > 30) {
      lastN = {};
      for (var i = 0; i < props.lastNId.length; i++)
        lastN[props.lastNId[i]] = props.lastNId[i];
    }
    for (let col = 0; col < numberOfCol; col++) {
      let rowView = [];
      for (let row = 0; row < numberOfRow; row++) {
        let userIndex = col * numberOfRow + row;
        if (userIndex >= users.length) {
          break;
        }
        // if (
        //   isNoOfUserGreater &&
        //   col === numberOfCol - 1 &&
        //   row === numberOfRow - 1
        // ) {
        //   lastNumber = userIndex;
        //   rowView.push(
        //     <div
        //       onClick={props.peopleClicked}
        //       style={{ width: 100 / numberOfRow + "%", maxWidth: "40%" }}
        //       className={`grid-box `}
        //     >
        //       <div className={"tile-wrapper"}>
        //         <div className="tile-more-people-icon-container">
        //           <Badge
        //             className={"tile-more-people-badge"}
        //             badgeContent={
        //               props.users.length -
        //               props.noOfUsersInTile * props.noOfUsersInTile +
        //               1
        //             }
        //             color="primary"
        //           >
        //             <PeopleOutlineIcon fontSize="medium" />
        //           </Badge>
        //         </div>
        //         <Typography className={"tile-more-people-text"} variant="h5">
        //           {"More"}
        //         </Typography>
        //         {/* <AudioViewComponent track={item.audioTrack} /> */}
        //       </div>
        //     </div>
        //   );
        //   break;
        // }

        rowView.push(
          <div
            key={users[userIndex].id}
            style={{
              width: 100 / numberOfRow + "%",
              maxWidth: "40%",
            }}
            className={`grid-box ${
              dominantSpeakerId === users[userIndex].id ? "show-border" : ""
            }`}
          >
            <RenderVideo
              // hideVideo={userIndex != 0 && lastN && !lastN[users[userIndex].id]}

              stats={props.stats}
              hideName
              size={
                props.avatarMode === "fullscreen"
                  ? "fill"
                  : props.avatarMode === "square"
                  ? "square"
                  : "medium"
              }
              withAudio={
                localUserId !== users[userIndex].id &&
                props.myScreenShareID !== users[userIndex].id
              }
              user={users[userIndex]}
            />
            <DisplayUserNameWithOption
              noisyMics={props.noisyMics}
              connectionQuality={
                props.connectionQuality &&
                props.connectionQuality[users[userIndex].id]
              }
              muteUser={props.muteUser}
              user={users[userIndex]}
              pinnedUserId={props.pinnedUserId}
              pinUser={props.pinUser}
              localizedObject={props.localizedObject}
              unPinUser={props.unPinUser}
            />
            <div
              onClick={() => props.pinUser(users[userIndex])}
              className="  rounded-full pin-option"
            >
              <PinIcon alt="Pin" className="pin-image" />
            </div>
          </div>
        );
      }
      colView.push(
        <div
          className="grid-row"
          style={{
            height: 100 / numberOfCol - 1 + "%",
            maxHeight: "50%",
          }}
        >
          {rowView}
        </div>
      );
    }
    if (isNoOfUserGreater) {
      for (let i = lastNumber; i < props.users.length; i++) {
        if (props.users[i].id !== props.myScreenShareID) {
          colView.push(
            <AudioViewComponent audioTrack={props.users[i].audioTrack} />
          );
        }
      }
    }
    return <div className={"tile-list-wrapper"}>{colView}</div>;
  };

  /**
   * pinnedUI render the ui when a user is pinned in grid mode it displays a man speaker at top and
   * al list of user in bottom maximum 4 user can be there in bottom list
   * @return {component}
   */
  const pinnedUI = () => {
    let mainUser = props.users[0];
    if (props.dominantSpeakerId && !props.pinnedUserId) {
      mainUser = props.users.find(
        (user) => user.id === props.dominantSpeakerId
      );
    }
    if (props.pinnedUserId) {
      mainUser = props.users.find((user) => user.id === props.pinnedUserId);
    }
    if (!mainUser) {
      mainUser = props.users[0];
    }
    let remainingUser = props.users.filter((user) => mainUser.id !== user.id);
    let { lastNId } = props;
    let userList = [];
    if (lastNId && remainingUser.length > 5) {
      for (let i = 1; i < remainingUser.length; i++) {
        if (lastNId.includes(remainingUser[i].id)) {
          userList.unshift({ ...remainingUser[i], inLastN: true });
        } else {
          userList.push(remainingUser[i]);
        }
      }
      userList.unshift(remainingUser[0]);
    } else {
      userList = remainingUser;
    }

    return (
      <div className={"tile-pinned-ui-main-container"}>
        <div className={"tile-pinned-ui-main-video-container "}>
          {mainUser.id === props.pinnedUserId ? (
            <div
              onClick={() => {
                props.unPinUser();
              }}
            >
              <PinIcon className={"tile-pinned-ui-un-pin-icon"} alt="Pin" />
            </div>
          ) : null}
          {props.myScreenShareID === mainUser.id ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#000",
                borderRadius: 8,
              }}
            >
              <p style={{ color: "#ffff" }}>
                {props.localizedObject["SCREEN_SHARE_MSG"] ||
                  "You are currently sharing your screen."}
              </p>
            </div>
          ) : (
            <RenderVideo
              hideName
              size={
                props.avatarMode === "fullscreen"
                  ? "fill"
                  : props.avatarMode === "square"
                  ? "square"
                  : "large"
              }
              withAudio={
                props.localUserId !== mainUser.id &&
                props.myScreenShareID !== mainUser.id
              }
              user={mainUser}
            />
          )}
          <DisplayUserNameWithOption
            noisyMics={props.noisyMics}
            connectionQuality={
              props.connectionQuality && props.connectionQuality[mainUser.id]
            }
            user={mainUser}
            pinnedUserId={props.pinnedUserId}
            pinUser={props.pinUser}
            unPinUser={props.unPinUser}
            localizedObject={props.localizedObject}
            muteUser={props.muteUser}
          />
        </div>
        {userList.length ? (
          <div className={"tile-pinned-ui-other-user-container"}>
            {userList.map((item, index) => {
              // if (index === 4 && remainingUser.length > 5) {
              //   return (
              //     <div
              //       className={"tile-pinned-ui-more-user-container"}
              //       onClick={() => {
              //         props.peopleClicked();
              //       }}
              //     >
              //       <Card
              //         onClick={props.peopleClicked}
              //         className={"tile-pinned-ui-more-user-wrapper"}
              //       >
              //         <div className="tile-more-people-icon-container">
              //           <Badge
              //             className={"tile-more-people-badge"}
              //             badgeContent={props.users.length - 5}
              //             color="primary"
              //           >
              //             <PeopleOutlineIcon fontSize="medium" />
              //           </Badge>
              //         </div>
              //         <Typography
              //           className="tile-more-people-text"
              //           variant="h5"
              //         >
              //           {props.localizedObject["MORE"]}
              //         </Typography>
              //         <AudioViewComponent audioTrack={item.audioTrack} />
              //       </Card>
              //     </div>
              //   );
              // } else
              if (index < 5) {
                return (
                  <div className={"tile-pinned-ui-remaining-user"}>
                    <RenderVideo
                      stats={props.stats}
                      cover
                      hideName
                      size={
                        props.avatarMode === "fullscreen"
                          ? "fill"
                          : props.avatarMode === "square"
                          ? "square"
                          : "large"
                      }
                      withAudio={
                        props.localUserId !== item.id &&
                        props.myScreenShareID !== item.id
                      }
                      user={item}
                    />
                    <DisplayUserNameWithOption
                      noisyMics={props.noisyMics}
                      connectionQuality={
                        props.connectionQuality &&
                        props.connectionQuality[item.id]
                      }
                      muteUser={props.muteUser}
                      user={item}
                      pinnedUserId={props.pinnedUserId}
                      pinUser={props.pinUser}
                      localizedObject={props.localizedObject}
                      unPinUser={props.unPinUser}
                    />
                  </div>
                );
              } else {
                if (item.id !== props.myScreenShareID) {
                  return <AudioViewComponent audioTrack={item.audioTrack} />;
                }
              }
            })}
          </div>
        ) : null}
      </div>
    );
  };
  renderNum++;
  console.log("renderNum for rendervideo for", renderNum);
  return (
    <div className={"tile-main-container"}>
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          color: "#fff",
          zIndex: 20,
        }}
      >
        {props.lastNId.map((item) => {
          return <div>{item}</div>;
        })}
      </div> */}
      {renderGridMode()}
    </div>
  );
};

export default React.memo(TileMode);
TileMode.propTypes = {
  unPinUser: PropTypes.func,
  pinUser: PropTypes.func,
  pinnedUserId: PropTypes.string,
  users: PropTypes.array,
  dominantSpeakerId: PropTypes.string,
  localUserId: PropTypes.string,
  peopleClicked: PropTypes.func,
  noOfUsersInTile: PropTypes.number,
  muteUser: PropTypes.func,
  localizedObject: PropTypes.object,
  screenShareUserID: PropTypes.string,
  room: PropTypes.object,
  lastNId: PropTypes.array,
  connectionQuality: PropTypes.object,
};
TileMode.defaultProps = {
  unPinUser: () => {},
  pinUser: () => {},
  pinnedUserId: "",
  users: [],
  dominantSpeakerId: "",
  localUserId: "",
  peopleClicked: () => {},
  noOfUsersInTile: 4,
  muteUser: () => {},
  localizedObject: {},
  screenShareUserID: "",
  room: {},
  lastNId: [],
  connectionQuality: {},
};
