import { createVirtualBackgroundEffect } from "../virtual-background";

export const addBackgroundEffect = async ({ track, option }) => {
  console.log("localVideoTrack", track);

  let finalOptions = undefined;
  switch (option.type) {
    case "blur":
      finalOptions = {
        backgroundEffectEnabled: true,
        backgroundType: "blur",
        blurValue: option.blurValue,
        selectedThumbnail: "blur",
      };
      break;
    case "img":
      finalOptions = {
        backgroundEffectEnabled: true,
        backgroundType: "image",
        blurValue: undefined,
        selectedThumbnail: "5",
        virtualSource: option.url,
      };
    default:
      break;
  }
  let temp = undefined;
  if (finalOptions) temp = await createVirtualBackgroundEffect(finalOptions);
  let newTrack = { ...track };
  track.setEffect(temp);
  return track;
};
