/* eslint-disable */
import Logger from "../../lib/Logger";
import React, { Component } from "react";

var user, appID, type, sessionID, settings;

var TAG = "CallingAnalytics";
export default class Analytics extends Component {
  constructor(props) {
    super(props);
    user = props.user;
    appID = props.appID;
    sessionID = props.sessionID;
    this.props = props;
    if (props.isAudioOnly) {
      type = "audio";
    } else {
      type = "video";
    }
    settings = this.props.analyticsSettings;
  }

  componentDidMount() {
    setInterval(() => {
      this.ping(this.props);
    }, 60000);
  }

  getAnalyticsUrl() {
    var url =
      "https://" +
      settings.ANALYTICS_HOST +
      "/" +
      settings.ANALYTICS_VERSION +
      "/calls/ping";
    return url;
  }

  async ping(props) {
    var myHeaders = new Headers();
    myHeaders.append("appId", appID);
    myHeaders.append("authorization", "Beare " + user.jwt);
    myHeaders.append("Content-Type", "application/json");
    console.log("isAudioOnly", props?.isAudioOnly);
    var data = {
      uts: Date.now(),
      wsId: user.resource,
      type: props?.isAudioOnly ? "audio" : "video",
      sessionId: sessionID,
      isRecording: false,
    };
    var raw = JSON.stringify(data);
    Logger.log(TAG, "Ping data = ", data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    Logger.log(TAG, "res", JSON.stringify(requestOptions));
    fetch(this.getAnalyticsUrl(), requestOptions)
      .then((response) => response.json())
      .then((result) => Logger.log(TAG, "Ping result => ", result.data))
      .catch((error) => Logger.log(TAG, "Ping error =>", error));
  }

  render() {
    return null;
  }
}
