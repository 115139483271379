// @flow

// import { NOTIFICATION_TIMEOUT_TYPE } from "../../notifications";
// import { showWarningNotification } from "../../notifications/actions";
// import { timeout } from "../../virtual-background/functions";
// import logger from "../../virtual-background/logger";

import JitsiStreamBackgroundEffect from "./JitsiStreamBackgroundEffect";
// import createTFLiteModule from "./vendor/tflite/tflite";
// import createTFLiteSIMDModule from "./vendor/tflite/tflite-simd";
const models = {
  modelLandscape: "libs/selfie_segmentation_landscape.tflite",
};

let tflite;
let wasmCheck;
let isWasmDisabled = false;
function timeout(milliseconds: number, promise: Promise<*>): Promise<Object> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("408"));

      return;
    }, milliseconds);

    promise.then(resolve, reject);
  });
}

const segmentationDimensions = {
  modelLandscape: {
    height: 144,
    width: 256,
  },
};

/**
 * Creates a new instance of JitsiStreamBackgroundEffect. This loads the Meet background model that is used to
 * extract person segmentation.
 *
 * @param {Object} virtualBackground - The virtual object that contains the background image source and
 * the isVirtualBackground flag that indicates if virtual image is activated.
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {Promise<JitsiStreamBackgroundEffect>}
 */
export async function loadModel() {
  if (!tflite && !isWasmDisabled) {
    try {
      wasmCheck = require("wasm-check");
      const tfliteTimeout = 10000;

      if (wasmCheck?.feature?.simd) {
        tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
      } else {
        tflite = await timeout(tfliteTimeout, createTFLiteModule());
      }
    } catch (err) {
      isWasmDisabled = true;

      if (err?.message === "408") {
        console.error("Failed to download tflite model!");
        // dispatch(
        //   showWarningNotification(
        //     {
        //       titleKey: "virtualBackground.backgroundEffectError",
        //     },
        //     NOTIFICATION_TIMEOUT_TYPE.LONG
        //   )
        // );
      } else {
        console.error(
          "Looks like WebAssembly is disabled or not supported on this browser",
          err
        );
        // dispatch(
        //   showWarningNotification(
        //     {
        //       titleKey: "virtualBackground.webAssemblyWarning",
        //       descriptionKey: "virtualBackground.webAssemblyWarningDescription",
        //     },
        //     NOTIFICATION_TIMEOUT_TYPE.LONG
        //   )
        // );
      }

      return false;
    }
  } else if (isWasmDisabled) {
    console.error("WasmDisabled");
    // dispatch(
    //   showWarningNotification(
    //     {
    //       titleKey: "virtualBackground.backgroundEffectError",
    //     },
    //     NOTIFICATION_TIMEOUT_TYPE.LONG
    //   )
    // );

    return false;
  }

  const modelBufferOffset = tflite._getModelBufferMemoryOffset();
  const modelResponse = await fetch(models.modelLandscape);
  console.log("modelResponse", modelResponse);
  if (!modelResponse.ok) {
    throw new Error("Failed to download tflite model!");
  }

  const model = await modelResponse.arrayBuffer();

  tflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);

  tflite._loadModel(model.byteLength);

  return true

}

export async function createVirtualBackgroundEffect(
  virtualBackground
) {
  if (
    !MediaStreamTrack.prototype.getSettings &&
    !MediaStreamTrack.prototype.getConstraints
  ) {
    throw new Error("JitsiStreamBackgroundEffect not supported!");
  }

  // Checks if WebAssembly feature is supported or enabled by/in the browser.
  // Conditional import of wasm-check package is done to prevent
  // the browser from crashing when the user opens the app.

  let loaded = await loadModel()
  if(!loaded) return
  const options = {
    ...segmentationDimensions.modelLandscape,
    virtualBackground,
  };

  return new JitsiStreamBackgroundEffect(tflite, options);
}
