import { Badge, Hidden, IconButton } from "@material-ui/core";
import { ReactComponent as PeopleOutlineIcon } from "../assets/users-icon.svg";
import PropTypes from "prop-types";
import React from "react";
import AudioButton from "./AudioButton";
import EndCallButton from "./EndCallButton";
import RecordingButton from "./RecordingButton";
import ScreenShareButton from "./ScreenShareButton";
import Settings from "./Settings";
import VideoButton from "./VideoButton";
import "./BottomButton.css";
import { LocalizedString } from "../../../Utils/Constants";
const BottomButtons = (props) => {
  return (
    <div
      className={
        props.mode === "SIDEBAR"
          ? "bottom-buttons-with-padding"
          : "bottom-buttons"
      }
    >
      {props.showButtons ? (
        <>
          <AudioButton
            isVisible={props.showAudioMuteButton}
            isMuted={Boolean(props.user.isAudioMuted || !props.user.audioTrack)}
            onPress={props.toggleAudioButton}
            localizedObject={props.localizedObject}
            noTrack={!props.user.audioTrack}
          />
          {props.isAudioOnly ? (
            <VideoButton
              isVisible={props.showVideoPauseButton}
              isMuted={false}
              localizedObject={props.localizedObject}
              onPress={props.toggleVideoButton}
              noTrack={false}
              isAudioOnly={props.isAudioOnly}
              usersLength={
                props.usersLength - props.isAnyScreenShareUser.length
              }
            />
          ) : (
            <VideoButton
              isVisible={props.showVideoPauseButton}
              isMuted={
                props.presenterTrack
                  ? Boolean(props.isPresenterTrackMuted)
                  : Boolean(props.user.isVideoMuted || !props.user.videoTrack)
              }
              localizedObject={props.localizedObject}
              onPress={props.toggleVideoButton}
              noTrack={!props.user.videoTrack}
            />
          )}
          <ScreenShareButton
            isOn={props.isScreenShareOn}
            isVisible={props.showScreenShareButton}
            localizedObject={props.localizedObject}
            onPress={props.onScreenShareClicked}
          />
          <RecordingButton
            isOn={props.isRecording}
            isVisible={props.showRecordingButton}
            onPress={props.recordingClicked}
            localizedObject={props.localizedObject}
          />
          <EndCallButton
            onPress={props.onEndCallButtonCLicked}
            isVisible={props.showEndCallButton}
            localizedObject={props.localizedObject}
          />
        </>
      ) : null}
      <div className={"bottom-buttons-other-options"}>
        <Settings
          setNoOfUsersPerPage={props.setNoOfUsersPerPage}
          noOfUsersPerPage={props.noOfUsersPerPage}
          isAudioOnly={props.isAudioOnly}
          localizedObject={props.localizedObject}
          noOfUsersInTile={props.noOfUsersInTile}
          setNoOfUsersInTile={props.setNoOfUsersInTile}
          showFullScreen={props.showFullScreen}
          showChangeModeButton={props.showChangeModeButton}
          changeMode={props.changeMode}
          mode={props.mode}
          activeInputDevices={props.activeInputDevices}
          activeOutputDevices={props.activeOutputDevices}
          changeAudioSourceClicked={props.changeAudioSourceClicked}
          changeVideoSourceClicked={props.changeVideoSourceClicked}
          changeAudioOutputDeviceClicked={props.changeAudioOutputDeviceClicked}
          audioOutPutDevices={props.audioOutPutDevices}
          audioDevices={props.audioDevices}
          videoDevices={props.videoDevices}
          peopleClicked={props.peopleClicked}
          activeVideoDevice={
            props.user && props.user.videoTrack
              ? props.user.videoTrack.deviceId
              : ""
          }
          onBackgroundEffectSelected={props.onBackgroundEffectSelected}
          selectedBg={props.selectedBg}
          appliedBg={props.appliedBg}
          SetImages={props.SetImages}
          localVideoTrack={props.localVideoTrack}
          setSelectedBg={props.setSelectedBg}
          db={props.db}
          AllowBackgroundBlur={props.AllowBackgroundBlur}
          AllowUserImages={props.AllowUserImages}
          ShowDefaultImages={props.ShowDefaultImages}
          isBackgroundModelVisible={props.isBackgroundModelVisible}
          setIsBackgroundModalVisible={props.setIsBackgroundModalVisible}
          EnforceBackgroundImage={props.EnforceBackgroundImage}
          ShowVirtualBackgroundSetting={props.ShowVirtualBackgroundSetting}
          EnforceBackgroundBlur={props.EnforceBackgroundBlur}
          addedBackgroundImage={props.addedBackgroundImage}
          addedBackgroundBlur={props.addedBackgroundBlur}
        />
        <Hidden xsDown>
          <IconButton
            component="span"
            className={"bottom-buttons-icon-button"}
            onClick={(e) => {
              e.stopPropagation();
              props.peopleClicked();
            }}
          >
            <Badge badgeContent={props.usersLength} color="primary">
              <PeopleOutlineIcon className={"bottom-buttons-icon-color"} />
            </Badge>
          </IconButton>
        </Hidden>
      </div>
    </div>
  );
};

BottomButtons.defaultProps = {
  isScreenShareOn: false,
  showAudioMuteButton: false,
  showVideoPauseButton: false,
  user: {},
  toggleAudioButton: () => {},
  toggleVideoButton: () => {},
  showScreenShareButton: false,
  onScreenShareClicked: () => {},
  isRecording: false,
  showRecordingButton: false,
  recordingClicked: () => {},
  onEndCallButtonCLicked: () => {},
  showFullScreen: false,
  showChangeModeButton: false,
  changeMode: () => {},
  mode: "SIDEBAR",
  activeInputDevices: "",
  activeOutputDevices: "",
  changeAudioSourceClicked: () => {},
  changeVideoSourceClicked: () => {},
  changeAudioOutputDeviceClicked: () => {},
  audioOutPutDevices: [],
  audioDevices: [],
  videoDevices: [],
  peopleClicked: () => {},
  noOfUsersInTile: 4,
  setNoOfUsersInTile: PropTypes.func,
  usersLength: 0,
  localizedObject: LocalizedString,
  showEndCallButton: true,
  showButtons: true,
};

BottomButtons.propTypes = {
  showAudioMuteButton: PropTypes.bool,
  user: PropTypes.object,
  toggleAudioButton: PropTypes.func,
  toggleVideoButton: PropTypes.func,
  showVideoPauseButton: PropTypes.bool,
  isScreenShareOn: PropTypes.bool,
  showScreenShareButton: PropTypes.bool,
  onScreenShareClicked: PropTypes.func,
  isRecording: PropTypes.bool,
  showRecordingButton: PropTypes.bool,
  recordingClicked: PropTypes.func,
  onEndCallButtonCLicked: PropTypes.func,
  showFullScreen: PropTypes.bool,
  showChangeModeButton: PropTypes.bool,
  changeMode: PropTypes.func,
  mode: PropTypes.string,
  activeInputDevices: PropTypes.string,
  activeOutputDevices: PropTypes.string,
  changeAudioSourceClicked: PropTypes.func,
  changeVideoSourceClicked: PropTypes.func,
  changeAudioOutputDeviceClicked: PropTypes.func,
  audioOutPutDevices: PropTypes.array,
  audioDevices: PropTypes.array,
  videoDevices: PropTypes.array,
  peopleClicked: PropTypes.func,
  noOfUsersInTile: PropTypes.number,
  setNoOfUsersInTile: PropTypes.func,
  usersLength: PropTypes.number,
  localizedObject: PropTypes.object,
  showEndCallButton: PropTypes.bool,
  showButtons: PropTypes.bool,
};

export default React.memo(BottomButtons);
