import create from "zustand";

const [useStatsStore, api] = create((set) => ({
  stats: {},
}));

export const updateStatsForUser = (id, stats) => {
  api.setState((state) => {
    return {
      ...state,
      stats: { ...state.stats, [id]: stats },
    };
  });
};

export const getConnectivityStatsForUser = (id, stats) => {
  return stats[id] || { connectionQuality: 100 };
};

export { useStatsStore };
